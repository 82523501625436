import { TextField } from "@mui/material";
import { useState, useCallback } from "react";
import { IoMdTrash } from "react-icons/io";
import ConfirmationDialog from "../../../common/confirmationDialog";

export default function Breadcrumb({
  breadcrumb,
  handleLocalBreadcrumbsChange,
  index,
  handleDelete,
}) {
  const [open, setOpen] = useState(false);

  const handleChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      const newBreadcrumb = { ...breadcrumb, [name]: value };
      handleLocalBreadcrumbsChange(newBreadcrumb, index);
    },
    [breadcrumb, handleLocalBreadcrumbsChange, index]
  );

  const handleConfirmDelete = useCallback(async () => {
    handleDelete(index);
    setOpen(false);
  }, [handleDelete, index, setOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <div>
      <ConfirmationDialog
        open={open}
        title={"Confirm"}
        content={"Do you want to delete this breadcrumb?"}
        onClose={handleClose}
        onConfirm={handleConfirmDelete}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <TextField
            name="text"
            variant="outlined"
            size="small"
            label="Text"
            fullWidth
            value={breadcrumb.text}
            onChange={handleChange}
          />
          <IoMdTrash
            color="red"
            size={30}
            cursor={"pointer"}
            onClick={() => setOpen(true)}
          />
        </div>

        <TextField
          name="link"
          variant="outlined"
          size="small"
          label="Link"
          multiline
          fullWidth
          minRows={2}
          value={breadcrumb.link}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
