import { Button, FormControl, TextField, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Box } from '@mui/material';
import { useCallback, useState } from 'react';
import { createProject } from '../../api/projects';
import { timezones } from './timezones';
import validator from './validator';
import { useEffect } from 'react';

const initialProject = { name: '', timezoneOffset: '00:00:00' };

export function ProjectEditor({ isOpen, onClose, onSave }) {
  const [project, setProject] = useState(initialProject);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setProject(initialProject);
    setErrors({});
  }, [isOpen]);

  const handleSave = useCallback(async () => {
    const errors = validator.validate(project);
    setErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      await createProject(project);
      onSave();
    } catch (e) {
    }
  }, [project, onSave]);

  const onChange = useCallback((event) => {
    const { name, value } = event.target;

    const newErrors = { ...errors };
    delete newErrors[name];
    setErrors(newErrors);

    setProject({
      ...project,
      [name]: value
    });
  }, [project, errors]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        New project
      </DialogTitle>
      <DialogContent>
        <Box style={{ width: 480, paddingTop: 10 }}>
          <Box style={{ marginBottom: 20 }}>
            <TextField
              sx={{ width: '100%' }}
              name="name"
              label="Name"
              value={project.name}
              variant="outlined"
              size="small"
              onChange={onChange}
              helperText={errors?.name}
            />
          </Box>

          <Box>
            <FormControl style={{ textAlign: 'left' }} sx={{ width: '100%' }}>
              <TextField
                select
                value={project.timezoneOffset}
                label="Timezone Offset"
                name="timezoneOffset"
                variant="outlined"
                size="small"
                onChange={onChange}
                helperText={errors?.timezoneOffset}
              >
                {timezones.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleSave}>Create</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
