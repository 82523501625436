import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import {
  setProjects,
  setCurrentProjectId,
} from "../../store/slices/projectSlice";
import { setCurrentSource } from "../../store/slices/sourceSlice";
import { fetchProjects } from "../../api/projects";
import { ProjectEditor } from "../projectEditor";
import { useState } from "react";
import CommonSelect from "../common/commonSelect";
import { logout } from "../../store/slices/authSlice";
import PrivateComponent from "../../providers/PrivateComponent/PrivateComponent";
import { PERMISSIONS } from "../../utils/constants";
import { Menu, MenuItem } from "@mui/material";

export default function Header() {
  const [isProjectDialogOpened, setProjectDialogOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const projects = useSelector((state) => state.project.projects);
  const currentProjectId = useSelector(
    (state) => state.project.currentProjectId
  );
  const sources = useSelector((state) => state.source.sources);
  const currentSource = useSelector((state) => state.source.currentSource);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  const handleChange = useCallback(
    (event) => {
      const { value, name } = event.target;
      if (name === "project") {
        dispatch(setCurrentProjectId(value));
        localStorage.setItem("currentProjectId", value);
      } else if (name === "source") {
        dispatch(setCurrentSource(value));
        localStorage.setItem("currentSource", value);
      }
    },
    [dispatch]
  );

  const setupProjects = useCallback(async () => {
    const projects = await fetchProjects();
    dispatch(setProjects(projects || []));
    dispatch(
      setCurrentProjectId(
        localStorage.getItem("currentProjectId") || projects[0].id
      )
    );
  }, [dispatch]);

  const setupCurrentSource = useCallback(async () => {
    dispatch(
      setCurrentSource(localStorage.getItem("currentSource") || sources[0].id)
    );
  }, [dispatch, sources]);

  const handleDialogSave = useCallback(() => {
    setProjectDialogOpened(false);
    setupProjects();
  }, [setupProjects]);

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    setupProjects();
    setupCurrentSource();
  }, [setupProjects, setupCurrentSource]);

  // const loggedInUser = useSelector((state) => state.auth.user);

  // useEffect(() => {
  //   !loggedInUser && navigate('/login');
  // }, [loggedInUser, navigate]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        style={{ background: "white", padding: "5px 0" }}
      >
        <Toolbar variant="dense">
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <List sx={{ display: "flex", flexDirection: "row" }}>
              <PrivateComponent permission={PERMISSIONS.ALLOW_READ_PREDICTION_TABLE}>
                <Link
                  to="match-predictions"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  <ListItemButton>Match Predictions</ListItemButton>
                </Link>
              </PrivateComponent>
              <PrivateComponent permission={PERMISSIONS.ALLOW_READ_MATCH_CENTER}>
                <Link
                  to="match-center"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  <ListItemButton>Match Center</ListItemButton>
                </Link>
              </PrivateComponent>
              <PrivateComponent permission={PERMISSIONS.ALLOW_READ_ODDS_CENTER}>
                <Link
                  to="odds-center"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  <ListItemButton>Odds Center</ListItemButton>
                </Link>
              </PrivateComponent>
              <PrivateComponent permission={PERMISSIONS.ALLOW_READ_STATISTICS}>
                <Link
                  to="points-table"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  <ListItemButton>Points Table</ListItemButton>
                </Link>
              </PrivateComponent>
              <div style={{ paddingLeft: '20px' }}>
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  variant="contained"
                >
                  More
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <PrivateComponent permission={PERMISSIONS.ALLOW_READ_USERS}>
                    <Link to="/users" style={{ color: "black", textDecoration: "none" }}>
                      <MenuItem onClick={handleClose}>Users</MenuItem>
                    </Link>
                  </PrivateComponent>
                  <PrivateComponent permission={PERMISSIONS.ALLOW_READ_COUNTRIES}>
                    <Link to="/countries" style={{ color: "black", textDecoration: "none" }}>
                      <MenuItem onClick={handleClose}>Countries</MenuItem>
                    </Link>
                  </PrivateComponent>
                  <PrivateComponent permission={PERMISSIONS.ALLOW_WRITE_COMPETITIONS}>
                    <Link to="/competitions" style={{ color: "black", textDecoration: "none" }}>
                      <MenuItem onClick={handleClose}>Competitions</MenuItem>
                    </Link>
                  </PrivateComponent>
                  <PrivateComponent permission={[PERMISSIONS.ALLOW_READ_COMPETITORS, PERMISSIONS.ALLOW_READ_PLAYERS]}>
                    <Link
                      to="teams-and-players"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <MenuItem onClick={handleClose}>Teams & Players</MenuItem>
                    </Link>
                  </PrivateComponent>
                  <PrivateComponent permission={PERMISSIONS.ALLOW_READ_VENUES}>
                    <Link
                      to="venues"
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <MenuItem onClick={handleClose}>Venues</MenuItem>
                    </Link>
                  </PrivateComponent>
                </Menu>
              </div>
            </List>
            <Box sx={{ display: "flex", gap: "15px" }}>
              <ProjectEditor
                onClose={() => setProjectDialogOpened(false)}
                onSave={handleDialogSave}
                isOpen={isProjectDialogOpened}
              />
              <PrivateComponent permission={PERMISSIONS.ALLOW_WRITE_PROJECTS}>
                <Button
                  variant="outlined"
                  onClick={() => setProjectDialogOpened(true)}
                  color="primary"
                >
                  Create Project
                </Button>
              </PrivateComponent>
              {!!projects.length && currentProjectId && (
                <CommonSelect
                  value={currentProjectId}
                  items={projects}
                  label="Project"
                  name="project"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                />
              )}
              {!!sources.length && currentSource && (
                <CommonSelect
                  value={currentSource}
                  items={sources}
                  label="Api"
                  name="source"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                />
              )}
              <Button variant="contained" color="error" onClick={handleLogout}>Logout</Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
