import { createSlice } from "@reduxjs/toolkit";

const sportsSlice = createSlice({
  name: "sports",
  initialState: {
    sportKeys: [
      { id: "cricket", title: "Cricket", source: 'SportsRadar' },
      { id: "football", title: "Football", source: 'SportsApi' },
      { id: "kabaddi", title: "Kabaddi", source: 'BlaskApi' },
    ],
  },
  reducers: {},
});

export default sportsSlice.reducer;
