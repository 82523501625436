
import { applyQueryParams } from "../utils/request";
import instance from "./api";

const fetchLeagues = async (params) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/leagues`;
  const res = await instance.get(
    applyQueryParams(endpoint, params)
  );
  return res.data;
};

const fetchAllLeaguesBySport = async (params) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/leagues`;
  const res = await instance.get(
    applyQueryParams(endpoint, [...params, {
      key: 'take',
      value: 10_000
    }, {
      key: 'skip',
      value: 0
    }])
  );
  return res.data?.data;
};

const enableLeague = async (projectID, leagueID) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/projects/${projectID}/leagues/${leagueID}/enable`;
  const res = await instance.post(endpoint);
  return res.data;
};

const disableLeague = async (projectID, leagueID) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/projects/${projectID}/leagues/${leagueID}/disable`;
  const res = await instance.post(endpoint);
  return res.data;
};

export { fetchLeagues, fetchAllLeaguesBySport, enableLeague, disableLeague };
