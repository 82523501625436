import { OddsNode } from "../oddsNode";

export default function OddsTree({ projectId, sport, configuration }) {
  return (
    <div style={{ paddingTop: "25px" }}>
      {configuration &&
        configuration.map((item) => (
          <OddsNode
            key={item.contentKey}
            projectId={projectId}
            sport={sport}
            item={item}
            level={1}
          />
        ))}
    </div>
  );
}
