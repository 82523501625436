import Login from "../../components/login";

export default function LoginPage() {
  return (
    <div style={{
      width: '100%', height: '100%', display: 'flex',
      justifyContent: 'center', alignItems: 'center'
    }}>
      <Login />
    </div>
  );
}
