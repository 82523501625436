import { useCallback, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { userLogin } from "../../api/login";
import { useDispatch } from "react-redux";
import { login } from "../../store/slices/authSlice";

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  const handleUsernameChange = useCallback((event) => {
    setUsername(event.target.value);
  }, []);
  const handlePasswordChange = useCallback((event) => {
    setPassword(event.target.value);
  }, []);
  const handleClick = useCallback(async () => {
    const data = {
      username,
      password
    }
    const res = await userLogin(data);
    dispatch(login(res));
  }, [username, password, dispatch]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', width: '500px', marginTop: '-61px' }}>
      <h2>Log In</h2>
      <TextField
        sx={{ width: "100%" }}
        name="username"
        label="Username"
        value={username}
        variant="outlined"
        size="small"
        onChange={handleUsernameChange}
      />
      <TextField
        sx={{ width: "100%" }}
        name="password"
        label="Password"
        value={password}
        variant="outlined"
        size="small"
        type="password"
        onChange={handlePasswordChange}
      />
      <Button variant="contained" disabled={!username || !password} onClick={handleClick}>Log In</Button>
    </Box>
  );
}
