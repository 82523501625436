import Box from "@mui/material/Box"
import OddsCenterItem from "./oddsCenterItem";

export default function OddsCenter() {
  const sport = { id: "cricket", title: "Cricket", source: 'Parimatch' }
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <OddsCenterItem sport={sport} />
    </Box>
  );
}
