import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { deleteUser, editUser, getUsers, setUser } from "../../api/users";
import EditAccount from "../../components/editAccount";
import PrivateComponent from "../../providers/PrivateComponent/PrivateComponent";
import hasPermissions from "../../utils/hasPermissions";
import { PERMISSIONS } from "../../utils/constants";

const createColumns = (handleEditAccount, handleDeleteUser, user) => {
  return [
    {
      field: "id",
      headerName: "ID",
      width: 100,
      sortable: false,
    },
    {
      field: "username",
      headerName: "Username",
      width: 150,
      sortable: false,
    },
    {
      field: "role",
      headerName: "Role name",
      width: 150,
      sortable: false,
    },
    {
      field: "isActive",
      headerName: "Is Active",
      width: 100,
      sortable: false,
      renderCell: ({ row }) =>
        !!row.isActive
          ? 'Yes'
          : "No",
    },
    {
      field: "permissions",
      headerName: "Permissions",
      width: 500,
      sortable: false,
      renderCell: ({ row }) => {
        return row.permissions.join(', ');
      }
    },
    {
      field: "projects",
      headerName: "Projects",
      width: 300,
      sortable: false,
      renderCell: ({ row }) => {
        const projects = row.projects.map((item) => item.name);
        return projects.join(', ');
      }
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      sortable: false,
      renderCell: ({ row }) => (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            padding: '5px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_USERS)}
            onClick={() => handleEditAccount(row)}
          >
            Edit
          </Button>
          {user && user.id !== row.id && <Button
            variant="contained"
            color="error"
            disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_USERS)}
            onClick={() => handleDeleteUser(row)}
          >
            Delete
          </Button>}
        </Box>
      ),
    },
  ];
};





export default function Accounts() {
  const [accounts, setAccounts] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [account, setAccount] = useState({ id: '', username: '', roleName: '', role: '', isActive: false, permissions: [], projects: [] })

  const user = JSON.parse(localStorage.getItem('user'));

  const getAccounts = useCallback(async () => {
    const queryParams = [
      { key: "take", value: pageSize },
      { key: "skip", value: page * pageSize },
    ];
    const res = await getUsers(queryParams);
    setAccounts(res.data);
    setTotal(res.total);
  }, [page, pageSize]);

  const handleDeleteUser = useCallback(async (account) => {
    if (
      window.confirm(
        `This action will delete "${account.username}" account. Do you wan't to proceed?`
      )
    ) {
      await deleteUser(account.id);
      await getAccounts();
    }
  }, [getAccounts]);

  const handleClose = useCallback(() => {
    const account = {
      id: '',
      username: '',
      roleName: '',
      role: '',
      isActive: false,
      permissions: [],
      projects: [],
    };
    setAccount(account);
    setIsOpen(false);
  }, []);

  const handleEditAccount = useCallback(async (account) => {
    setAccount(account);
    setIsOpen(true);
  }, []);

  const handleCreateAccount = useCallback(() => {
    const account = {
      id: '',
      username: '',
      roleName: '',
      role: '',
      isActive: false,
      permissions: [],
      projects: [],
    };
    setAccount(account);
    setIsOpen(true);
  }, []);

  const handleSubmit = useCallback(async (account, id) => {
    if (!id) {
      await setUser(account);
    } else {
      await editUser(account, id);
    }
    await getAccounts();
    handleClose();
  }, [getAccounts, handleClose]);

  const columns = useMemo(() => {
    return createColumns(handleEditAccount, handleDeleteUser, user);
  }, [handleEditAccount, handleDeleteUser, user]);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPage(0);
    setPageSize(newPageSize);
  }, []);

  useEffect(() => {
    getAccounts();
  }, [getAccounts])

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
        <h2>Accounts</h2>
        <PrivateComponent permission={PERMISSIONS.ALLOW_WRITE_USERS}>
          <Button variant="contained" onClick={handleCreateAccount}>Create account</Button>
        </PrivateComponent>
      </div>
      {isOpen && <Dialog maxWidth="lg" open={isOpen} onClose={handleClose}>
        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          {account && account.id ? `Edit ${account.username} account` : 'Create new account'}
        </DialogTitle>
        <DialogContent style={{ padding: '20px 24px' }}>
          <EditAccount account={account} user={user} handleClose={handleClose} onSubmit={handleSubmit} />
        </DialogContent>
      </Dialog>}
      {accounts && <Box sx={{ height: "700px", width: "100%" }}>
        <DataGrid
          getRowHeight={() => 'auto'}
          rows={accounts}
          columns={columns}
          rowCount={total}
          pageSize={pageSize}
          page={page}
          paginationMode="server"
          onPageChange={setPage}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[25, 50, 100]}
          // sortingMode="server"
          // onSortModelChange={handleSortModelChange}
          disableSelectionOnClick
          disableColumnFilter
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>}
    </div>
  );
}
