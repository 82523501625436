import instance from "./api";

const endpoints = {
  configuration: (projectId, sport) =>
    `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/oddscentre/${sport}/configuration`,
  content: (projectId, sport, contentKey) =>
    `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/oddscentre/${sport}/configuration/${contentKey}/content`,
  events: (projectId, sport) =>
    `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/oddscentre/${sport}/events`,
};

const fetchOddsCenterConfiguration = async (projectId, sport ,params) => {
  const queryParams = params
    ? params.map((x) => `${x.key}=${x.value}`).join("&")
    : "";
  const endpoint = endpoints.configuration(projectId, sport);
  const res = await instance.get(
    `${endpoint}${queryParams ? `?${queryParams}` : ""}`
  );
  return res.data;
};
const fetchOddsCenterContent = async (projectId, sport, contentKey, params) => {
  const queryParams = params
    ? params.map((x) => `${x.key}=${x.value}`).join("&")
    : "";
  const endpoint = endpoints.content(projectId, sport, contentKey);
  const res = await instance.get(
    `${endpoint}${queryParams ? `?${queryParams}` : ""}`
  );
  return res.data;
};
const fetchOddsCenterEvents = async (projectId, sport,  params) => {
  const queryParams = params
    ? params.map((x) => `${x.key}=${x.value}`).join("&")
    : "";
  const endpoint = endpoints.events(projectId, sport);
  const res = await instance.get(
    `${endpoint}${queryParams ? `?${queryParams}` : ""}`
  );
  return res.data;
};

const setOddsCenterConfiguration = async (projectId, sport, data) => {
  const res = await instance.post(endpoints.configuration(projectId, sport), data);
  return res.data;
};

const setOddsCenterContent = async (projectId, sport, contentKey, data) => {
  const res = await instance.put(endpoints.content(projectId, sport, contentKey), data);
  return res.data;
};

export {
  fetchOddsCenterConfiguration,
  setOddsCenterConfiguration,
  fetchOddsCenterEvents,
  fetchOddsCenterContent,
  setOddsCenterContent,
};
