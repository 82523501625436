import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import { getAllCompetitionsByLeague } from "../../../../api/competitions";
import { useCallback, useEffect, useMemo, useState } from "react";

const getHashKey = (level, id) => `${level}-${id}`;

export function ConfigureControl({
  item,
  hash,
  level,
  onHashChange,
  parentId,
  sport,
}) {
  const [data, setData] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isInited, setIsInited] = useState(false);

  const hashItem = useMemo(
    () => hash[getHashKey(level, item.id)],
    [hash, level, item]
  );
  const isChecked = useMemo(() => !!hashItem, [hashItem]);
  const itemValue = useMemo(
    () => hashItem || { ...item, level, parentId, displayOrder: "" },
    [hashItem, item, level, parentId]
  );

  const padding = useMemo(() => {
    return 2 * level;
  }, [level]);

  const isAllChecked = useMemo(
    () =>
      data.length ===
      data.filter((item) => hash[getHashKey(level + 1, item.id)]).length,
    [data, hash, level]
  );

  const getCompetitions = useCallback(async () => {
    const params = [
      { key: "sportId", value: sport.id },
      { key: "leagueId", value: item.id },
      { key: "source", value: sport.source },
    ];
    const competitions = await getAllCompetitionsByLeague(params);
    setData(competitions);
  }, [item, sport]);

  const initChildren = useCallback(() => {
    if (level === 2) {
      getCompetitions();
    }
  }, [getCompetitions, level]);

  const toggleCollaps = useCallback(() => {
    initChildren();
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed, initChildren]);

  const toggleChecked = useCallback(() => {
    onHashChange([itemValue], !isChecked);
    setIsCollapsed(!isChecked);
  }, [isChecked, itemValue, onHashChange]);

  const handleDisplayOrderChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      onHashChange([{ ...itemValue, [name]: value }], isChecked);
    },
    [isChecked, itemValue, onHashChange]
  );

  const handleCheckedAll = useCallback(() => {
    onHashChange(
      data.map((item) => {
        const l = level + 1;
        return {
          ...(hash[getHashKey(l, item.id)] || {}),
          ...item,
          level: l,
        };
      }),
      !isAllChecked
    );
  }, [data, isAllChecked, onHashChange, level, hash]);

  const handleChildrenChange = useCallback(
    (items, state) => {
      if (!state) return onHashChange(items, state);
      const newItems = [...items, itemValue];
      onHashChange(newItems, state);
    },
    [onHashChange, itemValue]
  );

  useEffect(() => {
    if (isInited) return;
    if (isChecked) {
      initChildren();
      setIsCollapsed(isChecked);
    }
    setIsInited(true);
  }, [initChildren, isChecked, setIsInited, isInited, hash]);

  return (
    <div>
      <List
        disablePadding
        sx={{
          background: "#f9f9f9",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Divider />
        <Box sx={{ width: "70px", ml: 2 }}>
          {isChecked && (
            <TextField
              sx={{ width: "100%" }}
              name="displayOrder"
              label="Order"
              value={itemValue?.displayOrder}
              variant="outlined"
              size="small"
              onChange={handleDisplayOrderChange}
            />
          )}
        </Box>

        <ListItemButton
          sx={{
            ml: 2,
            paddingLeft: padding,
            display: "flex",
            justifyContent: "space-between",
          }}
          onClick={toggleCollaps}
        >
          <FormControlLabel
            label={item.name}
            control={
              <Checkbox
                name={item.id.toString()}
                checked={isChecked}
                onChange={toggleChecked}
              />
            }
          />
          {isCollapsed && level < 3 && (
            <FormControlLabel
              label="Enable all"
              sx={{
                fontSize: "14px",
              }}
              onClick={(e) => e.stopPropagation()}
              control={
                <Checkbox
                  name={item.id.toString()}
                  sx={{
                    fontSize: "14px",
                  }}
                  checked={isAllChecked}
                  onChange={handleCheckedAll}
                />
              }
            />
          )}
        </ListItemButton>
      </List>
      <Collapse in={isCollapsed}>
        {isCollapsed &&
          data.map((children) => (
            <ConfigureControl
              key={children.id}
              hash={hash}
              item={children}
              level={level + 1}
              onHashChange={handleChildrenChange}
              parentId={item.id}
            />
          ))}
      </Collapse>
    </div>
  );
}
