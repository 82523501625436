export function getQueryString(params) {
    if (!params || !params.length) {
        return "";
    }

    return params.map((x) => `${x.key}=${x.value}`).join("&");
}

export function applyQueryParams(url, params) {
    const queryString = getQueryString(params);

    if (!queryString) {
        return url;
    }

    const endsWithQuestionMark = url.endsWith("?");

    if (endsWithQuestionMark) {
        return `${url}${queryString}`;
    }

    if (url.indexOf("?") > 0) {
        return `${url}&${queryString}`;
    }

    return `${url}?${queryString}`;
}