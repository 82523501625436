import { applyQueryParams } from "../utils/request";
import instance from "./api";

const getCompetitions = async (params) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/competitions`;
  const res = await instance.get(
    applyQueryParams(endpoint, params)
  );
  return res.data;
};

const getAllCompetitionsByLeague = async (params) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/competitions`;
  const res = await instance.get(
    applyQueryParams(endpoint, [...params, {
      key: 'take',
      value: 10_000
    }, {
      key: 'skip',
      value: 0
    }])
  );
  return res.data?.data;
};

export { getAllCompetitionsByLeague, getCompetitions };
