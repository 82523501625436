import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
  name: "project",
  initialState: {
    projects: [],
    currentProjectId: null,
  },
  reducers: {
    setCurrentProjectId(state, action) {
      state.currentProjectId = action.payload;
    },
    setProjects(state, action) {
      state.projects = action.payload;
    },
  },
});

export default projectSlice.reducer;
export const { setCurrentProjectId, setProjects } = projectSlice.actions;
