import { useEffect, useState, useCallback } from "react";
import { Card } from "./style";
import BreadcrumbsList from "../breadcrumbsList";
import SectionList from "../sectionList";
import { Button } from "@mui/material";
import { toast } from "react-hot-toast";
import CustomFieldList from "../customFieldsList";
export default function EditContent({
  onSave,
  getContent,
  setContent,
  showFields,
  contentKey,
}) {
  const [localContent, setLocalContent] = useState(null);

  const handleContentChange = useCallback(
    (key, value) => {
      const newContent = { ...localContent.content, [key]: value };
      setLocalContent({ ...localContent, content: newContent });
    },
    [localContent]
  );

  const handleCloseDialog = useCallback(() => {
    onSave();
  }, [onSave]);

  const fetchContent = useCallback(async () => {
    const data = await getContent(contentKey);
    setLocalContent(data);
  }, [contentKey, getContent]);

  const handleSaveChanges = useCallback(async () => {
    const content = localContent.content;
    await setContent(contentKey, content);
    toast.success("Content was successfully saved.");
  }, [contentKey, localContent, setContent]);

  useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  return (
    localContent && (
      <div>
        <div style={{ display: "flex", gap: "50px" }}>
          <Card>
            <BreadcrumbsList
              breadcrumbs={localContent.content.breadCrumbs}
              onContentChange={handleContentChange}
            />
          </Card>
          <Card>
            <SectionList
              sections={localContent.content.sections}
              onContentChange={handleContentChange}
            />
          </Card>
          {showFields && (
            <Card>
              <CustomFieldList
                customFields={localContent.content.customFields}
                onContentChange={handleContentChange}
              />
            </Card>
          )}
        </div>
        <div style={{ display: "flex", gap: "10px", paddingTop: "50px" }}>
          <Button
            variant="contained"
            color="success"
            onClick={handleSaveChanges}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
        </div>
      </div>
    )
  );
}
