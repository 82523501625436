import styled from "styled-components";

const Card = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgb(58 53 65 / 10%) 0px 2px 10px 0px;
`;

export { Card };
