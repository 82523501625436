import { Box, Button, Dialog, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import hasPermissions from "../../utils/hasPermissions";
import { getCountries } from "../../api/countries";
import { PERMISSIONS } from "../../utils/constants";
import EditContentTabs from "../../components/editContentTabs";
import { getContent, setContent } from "../../api/content";
import { useSelector } from "react-redux";

const createColumns = (handleOpenDialog) => {
  return [
    {
      field: "id",
      headerName: "Id",
      flex: 1,
    },
    {
      field: "code",
      headerName: "Code",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "region",
      headerName: "Region",
      flex: 1,
    },
    {
      field: "code3",
      headerName: "Code 3",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            padding: '5px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_COUNTRIES)}
            onClick={() => handleOpenDialog(row)}
          >
            Edit
          </Button>
        </Box>
      ),
    },
  ];
};





export default function Countries() {
  const projectId = useSelector((state) => state.project.currentProjectId);
  const [countries, setCountries] = useState(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [dialog, setDialog] = useState(false);
  const [currentCountry, setCurrentCountry] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [filters, setFilters] = useState({
    code: "",
    name: "",
    code3: "",
  });
  const [tempFilters, setTempFilters] = useState({
    code: "",
    name: "",
    code3: "",
  });

  const closeDialog = () => {
    setDialog(false);
  };

  const createParams = useCallback(() => {
    const filterParams = [];
    Object.keys(filters).forEach((key) => {
      if (!filters[key]) return;
      filterParams.push({ key, value: filters[key] });
    });
    const pageParams = [
      { key: "take", value: pageSize },
      { key: "skip", value: page * pageSize },
    ];
    return [...filterParams, ...pageParams];
  }, [filters, page, pageSize]);

  const handleFiltersChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setTempFilters({ ...tempFilters, [name]: value });
    },
    [tempFilters]
  );

  const handleSearchClick = useCallback(() => {
    setPage(0);
    setFilters(tempFilters);
  }, [tempFilters]);

  const getCountryList = useCallback(async () => {
    const queryParams = createParams();
    const res = await getCountries(queryParams);
    setCountries(res.data);
    setTotal(res.total);
  }, [createParams]);

  const handleResetFilters = useCallback(async () => {
    setFilters({
      code: "",
      name: "",
      code3: "",
    });
    setTempFilters({
      code: "",
      name: "",
      code3: "",
    });
    setPage(0);
  }, []);

  const handleOpenDialog = useCallback((item) => {
    setCurrentCountry(item);
    setDialog(true);
  }, []);

  const columns = useMemo(() => {
    return createColumns(handleOpenDialog);
  }, [handleOpenDialog]);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPage(0);
    setPageSize(newPageSize);
  }, []);

  const contentKeys = [
    { title: "Teams", contentPostfix: "Competitors" },
    { title: "Players", contentPostfix: "Players" },
  ];

  const handleGetCatalogContent = useCallback(
    async (contentKey) => {
      const country = currentCountry || {};
      const data = await getContent(projectId, 'Country', `${country.id}-${contentKey}`);
      return data;
    },
    [projectId, currentCountry]
  );

  const handleSetCatalogContent = useCallback(
    async (contentKey, content) => {
      const country = currentCountry || {};
      const data = await setContent(projectId, 'Country', `${country.id}-${contentKey}`, content);
      return data;
    },
    [projectId, currentCountry]
  );


  useEffect(() => {
    getCountryList();
  }, [getCountryList])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
      <h1>Countries</h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "12px",
          height: "100px",
        }}
      >
        filter by
        <TextField
          name="code"
          variant="outlined"
          size="small"
          label="Code"
          value={tempFilters.code}
          onChange={(event) => handleFiltersChange(event)}
        />
        <TextField
          name="name"
          variant="outlined"
          size="small"
          label="Name"
          value={tempFilters.name}
          onChange={(event) => handleFiltersChange(event)}
        />
        <TextField
          name="code3"
          variant="outlined"
          size="small"
          label="Code 3"
          value={tempFilters.code3}
          onChange={(event) => handleFiltersChange(event)}
        />
        <Button variant="contained" onClick={() => handleSearchClick()}>
          Search
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => handleResetFilters()}
        >
          Reset
        </Button>
      </div>
      {countries && <Box sx={{ height: "700px", width: "100%" }}>
        <DataGrid
          getRowHeight={() => 'auto'}
          rows={countries}
          columns={columns}
          rowCount={total}
          pageSize={pageSize}
          page={page}
          paginationMode="server"
          onPageChange={setPage}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[25, 50, 100]}
          disableSelectionOnClick
          disableColumnFilter
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>}
      {currentCountry && (
        <Dialog fullScreen open={dialog} onClose={closeDialog}>
          <Box>
            <EditContentTabs
              item={{ contentKey: '' }}
              keys={contentKeys}
              closeDialog={closeDialog}
              getContent={handleGetCatalogContent}
              setContent={handleSetCatalogContent}
            />
          </Box>
        </Dialog>
      )}
    </div>
  );
}
