import { MatchPredictionsWrapper } from "./style";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { fetchEvents, editEvent } from "../../api/matchPredictions";
import { UrlsEditor } from "../urlsEditor";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { PERMISSIONS } from "../../utils/constants";
import hasPermissions from "../../utils/hasPermissions";

const createColumns = (handleChange, handleClick, handleOpenUrlsEditor) => {
  return [
    {
      field: "id",
      headerName: "ID",
      width: 90,
    },
    {
      field: "sport",
      headerName: "Sport",
      width: 90,
    },
    {
      field: "league",
      headerName: "League",
      width: 90,
    },
    {
      field: "competition",
      headerName: "Competition",
      width: 250,
      sortable: false,
    },
    {
      field: "date",
      headerName: "Date",
      width: 120,
      renderCell: (row) => (
        <span>
          {format(new Date(row.row.date), "dd MMM yyyy HH:mm", {
            timeZone: "UTC",
          })}{" "}
          UTC
        </span>
      ),
    },
    {
      field: "firstTeam",
      headerName: "Team 1",
      width: 120,
      sortable: false,
      renderCell: (row) => <span>{row.row.competitors?.[0]?.name}</span>,
    },
    {
      field: "secondTeam",
      headerName: "Team 2",
      width: 120,
      sortable: false,
      renderCell: (row) => <span>{row.row.competitors?.[1]?.name}</span>,
    },
    {
      field: "winProbability",
      headerName: "Win probability",
      width: 200,
      sortable: false,
      renderCell: (row) => (
        <TextField
          name="competitorOneWinChance"
          variant="outlined"
          size="small"
          label="Win %"
          type="number"
          disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_PREDICTION_TABLE)}
          value={row.row.competitorOneWinChance || ""}
          onChange={(event) => handleChange(row.id, event)}
        />
      ),
    },
    {
      field: "place",
      headerName: "Place",
      width: 200,
      sortable: false,
      renderCell: (row) => (
        <TextField
          name="place"
          label="Place"
          variant="outlined"
          size="small"
          value={row.row.place || ""}
          disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_PREDICTION_TABLE)}
          onChange={(event) => {
            handleChange(row.id, event);
          }}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 220,
      sortable: false,
      renderCell: (row) => (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_PREDICTION_TABLE)}
            onClick={() => handleOpenUrlsEditor(row.id)}
          >
            Edit URL
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_PREDICTION_TABLE)}
            onClick={() => handleClick(row)}
          >
            Save
          </Button>
        </Box>
      ),
    },
  ];
};

export default function MatchPredictions() {
  const [events, setEvents] = useState(null);
  const [isUrlsEditorOpened, setIsUrlsEditorOpened] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [sortOptions, setSortOptions] = useState([
    { field: "date", sort: "desc" },
  ]);
  const [filters, setFilters] = useState({
    sport: "",
    league: "",
    competition: "",
  });
  const [tempFilters, setTempFilters] = useState({
    sport: "",
    league: "",
    competition: "",
  });
  const currentSource = useSelector((state) => state.source.currentSource);

  const createParams = useCallback(() => {
    const filterParams = [];
    Object.keys(filters).forEach((key) => {
      if (!filters[key]) return;
      filterParams.push({ key, value: filters[key] });
    });
    const pageParams = [
      { key: "take", value: pageSize },
      { key: "skip", value: page * pageSize },
    ];
    const sortParams = [
      { key: "sortBy", value: sortOptions?.[0]?.field || "date" },
      { key: "sortDirection", value: sortOptions?.[0]?.sort || "desc" },
    ];
    const sourceParams = [{ key: "source", value: currentSource }];
    return [...filterParams, ...pageParams, ...sortParams, ...sourceParams];
  }, [filters, page, pageSize, sortOptions, currentSource]);

  const fetchEventList = useCallback(async () => {
    if (!currentSource) {
      return;
    }
    const queryParams = createParams();
    const events = await fetchEvents(queryParams);
    setEvents(events.data);
    setTotal(events.total);
  }, [createParams, currentSource]);

  const handleChange = useCallback(
    (id, event) => {
      const { name, value } = event.target;
      const newRowIndex = [...events].findIndex((row) => row.id === id);
      const newEvents = [...events];
      newEvents[newRowIndex][name] = value;
      setEvents(newEvents);
    },
    [events]
  );

  const handleFiltersChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setTempFilters({ ...tempFilters, [name]: value });
    },
    [tempFilters]
  );

  const handleSearchClick = useCallback(() => {
    setPage(0);
    setFilters(tempFilters);
  }, [tempFilters]);

  const handleClick = useCallback(
    async (row) => {
      const { id, competitorOneWinChance, place } = row.row;
      const params = {
        competitorOneWinChance:
          competitorOneWinChance === "" ? null : competitorOneWinChance,
        place,
      };
      await editEvent(id, params);
      await fetchEventList();
    },
    [fetchEventList]
  );

  const handleSortModelChange = useCallback((sortModel) => {
    setPage(0);
    setSortOptions([...sortModel]);
  }, []);

  const handleResetFilters = useCallback(async () => {
    setFilters({ sport: "", league: "", competition: "" });
    setTempFilters({ sport: "", league: "", competition: "" });
    setPage(0);
  }, []);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPage(0);
    setPageSize(newPageSize);
  }, []);

  useEffect(() => {
    async function fetchData() {
      await fetchEventList();
    }

    fetchData();
  }, [createParams, fetchEventList]);

  const handleOpenUrlsEditor = useCallback((id) => {
    setSelectedEventId(id);
    return setIsUrlsEditorOpened(true);
  }, []);

  const columns = useMemo(() => {
    return createColumns(handleChange, handleClick, handleOpenUrlsEditor);
  }, [handleChange, handleClick, handleOpenUrlsEditor]);

  const handleUrlsEditorClose = useCallback(() => {
    setIsUrlsEditorOpened(false);
  }, []);

  return (
    <>
      <UrlsEditor open={isUrlsEditorOpened} id={selectedEventId} onClose={handleUrlsEditorClose}></UrlsEditor>
      <MatchPredictionsWrapper>
        {events && (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "12px",
                height: "100px",
              }}
            >
              filter by
              <TextField
                name="sport"
                variant="outlined"
                size="small"
                label="Sport"
                value={tempFilters.sport}
                onChange={(event) => handleFiltersChange(event)}
              />
              <TextField
                name="league"
                variant="outlined"
                size="small"
                label="League"
                value={tempFilters.league}
                onChange={(event) => handleFiltersChange(event)}
              />
              <TextField
                name="competition"
                variant="outlined"
                size="small"
                label="Competition"
                value={tempFilters.competition}
                onChange={(event) => handleFiltersChange(event)}
              />
              <Button variant="contained" onClick={() => handleSearchClick()}>
                Search
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={() => handleResetFilters()}
              >
                Reset
              </Button>
            </div>
            <Box sx={{ height: "700px", width: "100%" }}>
              <DataGrid
                rows={events}
                columns={columns}
                rowCount={total}
                pageSize={pageSize}
                page={page}
                paginationMode="server"
                onPageChange={setPage}
                onPageSizeChange={handlePageSizeChange}
                rowsPerPageOptions={[25, 50, 100]}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                disableSelectionOnClick
                disableColumnFilter
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Box>
          </div>
        )}
      </MatchPredictionsWrapper>
    </>
  );
}
