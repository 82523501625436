import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useCallback, useEffect, useState } from "react";
import ConfigureList from "../../common/configuration/confiugreList";
import CompetitionTree from "../competitionTree";
import CompetitionTable from "../competitionTable";

import { useSelector } from "react-redux";
import {
  fetchPointsTableConfiguration,
  setPointsTableConfiguration,
} from "../../../api/pointsTable";
import PrivateComponent from "../../../providers/PrivateComponent/PrivateComponent";
import { PERMISSIONS } from "../../../utils/constants";

export default function PointsTableItem({ sport }) {
  const [dialog, setDialog] = useState(false);
  const [configuration, setConfiguration] = useState(null);
  const [initialHash, setInitialHash] = useState(null);
  const [version, setVersion] = useState(null);

  const projectId = useSelector((state) => state.project.currentProjectId);

  const createHash = useCallback((configuration, level, parentId) => {
    const hash = configuration.reduce((a, c) => {
      let childrenHash = null;
      if (c.children.length > 0) {
        childrenHash = createHash(c.children, level + 1, c.entityId);
      }
      a[`${level}-${c.entityId}`] = {
        level,
        id: c.entityId,
        parentId,
        displayOrder: c.displayOrder,
      };
      return { ...a, ...childrenHash };
    }, {});
    return hash;
  }, []);

  const fetchConfiguration = useCallback(async () => {
    if (!projectId) return;
    const params = [{ key: "source", value: sport.source }];
    const configuration = await fetchPointsTableConfiguration(
      projectId,
      sport.id,
      params
    );
    const hashTable = createHash(configuration, 1, null);
    setInitialHash(hashTable);
    setConfiguration(configuration);
  }, [projectId, sport, createHash]);

  const onSubmit = useCallback(() => {
    fetchConfiguration();
    setVersion(Date.now());
  }, [fetchConfiguration]);

  const openDialog = useCallback(() => {
    setDialog(true);
  }, []);

  const closeDialog = useCallback(() => {
    setDialog(false);
  }, []);

  useEffect(() => {
    fetchConfiguration();
  }, [fetchConfiguration]);

  return (
    <div style={{ padding: "25px" }}>
      <div style={{ textAlign: "center" }}>
        <h1>Points table structure</h1>
        <PrivateComponent permission={PERMISSIONS.ALLOW_WRITE_STATISTICS}>
          <Button variant="contained" color="primary" onClick={openDialog}>
            Configure
          </Button>
        </PrivateComponent>
        <div>
          <Dialog fullScreen open={dialog} onClose={closeDialog}>
            <AppBar sx={{ position: "relative" }} color="transparent">
              <Toolbar>
                <Button onClick={closeDialog} color="primary">
                  Close
                </Button>
              </Toolbar>
            </AppBar>
            <ConfigureList
              projectId={projectId}
              sport={sport}
              onCloseDialog={closeDialog}
              onSubmit={onSubmit}
              initialHash={initialHash}
              setConfiguration={setPointsTableConfiguration}
            />
          </Dialog>
          <CompetitionTree
            projectId={projectId}
            sport={sport}
            configuration={configuration}
          />
        </div>
      </div>

      <div style={{ textAlign: "center" }}>
        <h1>Competitions</h1>
        <CompetitionTable projectId={projectId} sport={sport} version={version} />
      </div>
    </div>
  );
}
