import { useEffect, useState, useCallback } from "react";
import Section from "./section";

export default function SectionList({ sections, onContentChange }) {
  const [localSections, setLocalSections] = useState(null);

  const handleLocalSectionsChange = useCallback(
    (key, value) => {
      const newSections = { ...localSections, [key]: value };
      setLocalSections(newSections);
      onContentChange("sections", newSections);
    },
    [onContentChange, localSections]
  );

  useEffect(() => {
    setLocalSections({ ...sections });
  }, [sections]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        width: "100%",
      }}
    >
      <h1 style={{ textAlign: "center", margin: "0 0 20px" }}>Sections</h1>
      {localSections &&
        Object.keys(localSections).map((section) => (
          <Section
            key={section}
            section={{ key: section, value: localSections[section] }}
            handleLocalSectionsChange={handleLocalSectionsChange}
          />
        ))}
    </div>
  );
}
