import { Dialog, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import EditContent from "../../editContentTabs/editContent";
import EditContentTabs from "../../editContentTabs";
import {
  fetchMatchCenterContent,
  setMatchCenterContent,
} from "../../../api/matchCenter";
import {
  getContent,
  setContent,
} from "../../../api/content";
import { fetchAllPlayers } from "../../../api/players";
import { format } from "date-fns";
import PrivateComponent from "../../../providers/PrivateComponent/PrivateComponent";
import { PERMISSIONS } from "../../../utils/constants";
import hasPermissions from "../../../utils/hasPermissions";

const createColumns = (handleOpenDialog) => {
  return [
    {
      field: "id",
      headerName: "ID",
      width: 100,
    },
    {
      field: "externalId",
      headerName: "External ID",
      width: 150,
    },
    {
      field: "sport",
      headerName: "Sport",
      width: 150,
    },
    {
      field: "name",
      headerName: "Full Name",
      width: 300,
    },
    {
      field: "country",
      headerName: "Country",
      width: 150,
    },
    {
      field: "competitor",
      headerName: "Competitor",
      width: 300,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 130,
      renderCell: ({ row }) =>
        !!row.createdAt
          ? format(new Date(row.createdAt), "dd.MM.yyyy HH:mm")
          : "-",
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 170,
      sortable: false,
      renderCell: (row) => (
        <Button
          variant="contained"
          color="primary"
          disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_PLAYERS)}
          onClick={() => handleOpenDialog(row.row)}
        >
          Edit
        </Button>
      ),
    },
  ];
};

export function PlayersBlock() {
  const projectId = useSelector((state) => state.project.currentProjectId);
  const currentSource = useSelector((state) => state.source.currentSource);
  const [players, setPlayers] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [currentPlayer, setCurrentPlayer] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [catalogDialog, setCatalogDialog] = useState(false);
  const [sortOptions, setSortOptions] = useState([
    { field: "name", sort: "asc" },
  ]);

  const [filters, setFilters] = useState({
    id: "",
    sport: "",
    name: "",
    competitor: "",
    country: "",
    urlSegment: "",
  });
  const [tempFilters, setTempFilters] = useState({
    id: "",
    sport: "",
    name: "",
    competitor: "",
    country: "",
    urlSegment: "",
  });

  const handleOpenDialog = useCallback((item) => {
    setCurrentPlayer(item);
    setDialog(true);
  }, []);

  const closeDialog = () => {
    setDialog(false);
  };

  const handleOpenCatalogDialog = useCallback(() => {
    setCatalogDialog(true);
  }, []);

  const closeCatalogDialog = () => {
    setCatalogDialog(false);
  };

  const createContentKey = useCallback(() => {
    const contentKey = `Player-${currentPlayer.id}`;
    return contentKey;
  }, [currentPlayer]);

  const createParams = useCallback(() => {
    const filterParams = [];
    Object.keys(filters).forEach((key) => {
      if (!filters[key]) return;
      filterParams.push({ key, value: filters[key] });
    });
    const pageParams = [
      { key: "take", value: pageSize },
      { key: "skip", value: page * pageSize },
    ];
    const sortParams = [
      { key: "sortBy", value: sortOptions?.[0]?.field || "name" },
      { key: "sortDirection", value: sortOptions?.[0]?.sort || "asc" },
    ];
    const sourceParams = [{ key: "source", value: currentSource }];
    return [...filterParams, ...pageParams, ...sortParams, ...sourceParams];
  }, [filters, page, pageSize, sortOptions, currentSource]);

  const fetchPlayersList = useCallback(async () => {
    if (!currentSource) {
      return;
    }
    const queryParams = createParams();
    const players = await fetchAllPlayers(queryParams);
    setPlayers(players.data);
    setTotal(players.total);
  }, [createParams, currentSource]);

  const handleFiltersChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setTempFilters({ ...tempFilters, [name]: value });
    },
    [tempFilters]
  );

  const handleSearchClick = useCallback(() => {
    setPage(0);
    setFilters(tempFilters);
  }, [tempFilters]);

  const handleSortModelChange = useCallback((sortModel) => {
    setPage(0);
    setSortOptions([...sortModel]);
  }, []);

  const handleResetFilters = useCallback(async () => {
    setFilters({
      sport: "",
      name: "",
      competitor: "",
      country: "",
      id: "",
      urlSegment: "",
    });
    setTempFilters({
      sport: "",
      name: "",
      competitor: "",
      country: "",
      id: "",
      urlSegment: "",
    });
    setPage(0);
    await fetchPlayersList();
  }, [fetchPlayersList]);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPage(0);
    setPageSize(newPageSize);
  }, []);

  const handleGetCurrentPlayerContent = useCallback(
    async (contentKey) => {
      const data = await fetchMatchCenterContent(projectId, 'cricket', contentKey);
      return data;
    },
    [projectId]
  );

  const handleSetCurrentPlayerContent = useCallback(
    async (contentKey, content) => {
      const data = await setMatchCenterContent(projectId, 'cricket', contentKey, content);
      return data;
    },
    [projectId]
  );

  const handleGetCatalogContent = useCallback(
    async (contentKey) => {
      const data = await getContent(projectId, 'Player', contentKey);
      return data;
    },
    [projectId]
  );

  const handleSetCatalogContent = useCallback(
    async (contentKey, content) => {
      const data = await setContent(projectId, 'Player', contentKey, content);
      return data;
    },
    [projectId]
  );

  const contentKeys = [
    { title: "Players", contentPostfix: "All" },
    { title: "Countries", contentPostfix: "Countries" },
    { title: "Tournaments", contentPostfix: "Competitions" },
    { title: "Teams", contentPostfix: "Competitors" },
    { title: "Women", contentPostfix: "Women" },
    { title: "Men", contentPostfix: "Men" },
  ];

  useEffect(() => {
    async function fetchData() {
      await fetchPlayersList();
    }

    fetchData();
  }, [createParams, fetchPlayersList]);

  const columns = useMemo(() => {
    return createColumns(handleOpenDialog);
  }, [handleOpenDialog]);

  return (
    <Box>
      {players && (
        <Box>
          <Box sx={{ display: "flex", gap: "25px", padding: "25px 0" }}>
            <h1 style={{ margin: 0 }}>Players Catalog</h1>
            <PrivateComponent permission={PERMISSIONS.ALLOW_WRITE_PLAYERS}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenCatalogDialog()}
              >
                Edit Catalog Content
              </Button>
            </PrivateComponent>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "12px",
              padding: "20px 0",
            }}
          >
            filter by
            <TextField
              name="id"
              variant="outlined"
              size="small"
              label="ID"
              value={tempFilters.id}
              onChange={(event) => handleFiltersChange(event)}
            />
            <TextField
              name="sport"
              variant="outlined"
              size="small"
              label="Sport"
              value={tempFilters.sport}
              onChange={(event) => handleFiltersChange(event)}
            />
            <TextField
              name="name"
              variant="outlined"
              size="small"
              label="Name"
              value={tempFilters.name}
              onChange={(event) => handleFiltersChange(event)}
            />
            <TextField
              name="competitor"
              variant="outlined"
              size="small"
              label="Competitor"
              value={tempFilters.competitor}
              onChange={(event) => handleFiltersChange(event)}
            />
            <TextField
              name="country"
              variant="outlined"
              size="small"
              label="Country"
              value={tempFilters.country}
              onChange={(event) => handleFiltersChange(event)}
            />
            <TextField
              name="urlSegment"
              variant="outlined"
              size="small"
              label="Url"
              value={tempFilters.urlSegment}
              onChange={(event) => handleFiltersChange(event)}
            />
            <Button variant="contained" onClick={() => handleSearchClick()}>
              Search
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleResetFilters()}
            >
              Reset
            </Button>
          </Box>
          <Box sx={{ height: "700px", width: "100%" }}>
            <DataGrid
              rows={players}
              columns={columns}
              rowCount={total}
              pageSize={pageSize}
              page={page}
              paginationMode="server"
              onPageChange={setPage}
              onPageSizeChange={handlePageSizeChange}
              rowsPerPageOptions={[25, 50, 100]}
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
              disableSelectionOnClick
              disableColumnFilter
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
        </Box>
      )}
      {currentPlayer && (
        <Dialog fullScreen open={dialog} onClose={closeDialog}>
          <Box sx={{ padding: "25px" }}>
            <EditContent
              onSave={closeDialog}
              getContent={handleGetCurrentPlayerContent}
              setContent={handleSetCurrentPlayerContent}
              showFields={true}
              contentKey={createContentKey()}
            />
          </Box>
        </Dialog>
      )}
      <Dialog fullScreen open={catalogDialog} onClose={closeCatalogDialog}>
        <EditContentTabs
          item={{ contentKey: '' }}
          keys={contentKeys}
          closeDialog={closeCatalogDialog}
          getContent={handleGetCatalogContent}
          setContent={handleSetCatalogContent}
        />
      </Dialog>
    </Box>
  );
}
