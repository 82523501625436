import { timezones } from "./timezones";

function validate({name, timezoneOffset}) {
    const errors = {};

    if (!name) errors.name = 'Name must be provided';
    if (!timezoneOffset) errors.timezoneOffset = 'Timezone Offset must be provided';
    if (!timezones.includes(timezoneOffset)) errors.timezoneOffset = 'Invalid Timezone Offset';

    return errors;
}

const validator = {
    validate
};
export default validator;