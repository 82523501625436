import { AppWrapper } from "./AppStyle";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import MatchPredictionPage from "./pages/matchPredictions";
import PredictionTableListPage from "./pages/predictionTableList";
import LoginPage from "./pages/login";
import { Toaster } from "react-hot-toast";
import ReactHotToast from "./components/common/toast";
import MatchCenterPage from "./pages/matchCenter";
import OddsCenterPage from "./pages/oddsCenter";
import { TeamsAndPlayersPage } from "./pages/teamsAndPlayers";
import { VenuesPage } from "./pages/venues";
import PointsTablePage from "./pages/pointsTable";
import Header from "./components/header";
import { useDispatch, useSelector } from "react-redux";
import Accounts from "./pages/accounts";
import { PERMISSIONS } from "./utils/constants";
import { useMemo } from "react";
import { useEffect } from "react";
import { getCurrentUser, getPermissions, getRoles } from "./api/users";
import { useCallback } from "react";
import { setUser } from "./store/slices/authSlice";
import Redirect from "./providers/Redirect";
import Countries from "./pages/countries";
import Competitions from "./pages/competitions";

const unauthorizedRoutes = [
  { path: "/login", element: <LoginPage /> },
  { path: "*", element: <Redirect to="/login" /> },
];
const authorizedRoutes = [
  { path: "/", element: <Home /> },
  { path: "match-predictions", element: <MatchPredictionPage />, permission: PERMISSIONS.ALLOW_READ_PREDICTION_TABLE},
  { path: "teams-and-players", element: <TeamsAndPlayersPage />, permission: [PERMISSIONS.ALLOW_READ_COMPETITORS, PERMISSIONS.ALLOW_READ_PLAYERS] },
  { path: "prediction-tables", element: <PredictionTableListPage />, permission: PERMISSIONS.ALLOW_READ_PREDICTION_TABLE },
  { path: "match-center", element: <MatchCenterPage />, permission: PERMISSIONS.ALLOW_READ_MATCH_CENTER },
  { path: "odds-center", element: <OddsCenterPage />, permission: PERMISSIONS.ALLOW_READ_ODDS_CENTER },
  { path: "points-table", element: <PointsTablePage />, permission: PERMISSIONS.ALLOW_READ_STATISTICS },
  { path: "venues", element: <VenuesPage />, permission: PERMISSIONS.ALLOW_READ_VENUES },
  { path: "users", element: <Accounts />, permission: PERMISSIONS.ALLOW_READ_USERS },
  { path: "countries", element: <Countries />, permission: PERMISSIONS.ALLOW_READ_COUNTRIES },
  { path: "competitions", element: <Competitions />, permission: PERMISSIONS.ALLOW_WRITE_COMPETITIONS },
  { path: "*", element: <Redirect to="/" /> },
];

function useUser () {
  const loggedInUser = useSelector((state) => state.auth.user);
  const user = useMemo(() => {
    return loggedInUser || JSON.parse(localStorage.getItem('user'));
  },[loggedInUser]);
  return user;
}

function App() {
  const dispatch = useDispatch();
  const loggedInUser = useUser();

  const isLogged = useMemo(() => {
    return !!loggedInUser;
  },[loggedInUser]);

  const routes = useMemo(() => {
    let user = loggedInUser;
    if (!user) {
      user = JSON.parse(localStorage.getItem('user'));
    }
    if (!user) {
      return false
    }
    const permissions = new Set (user.permissions);
    return authorizedRoutes.filter((item) => { 
      if(Array.isArray(item.permission)) {
        const commonItems = item.permission.filter(item => permissions.has(item));
        if(commonItems.length === item.permission.length) {
          return true;
        }
        return false;
      }
      else {
         return !item.permission || permissions.has(item.permission);
      }
    }); 
  },[loggedInUser]);

  const getUser = useCallback(async () => {
    if(!localStorage.getItem('user')) {
      return;
    }
    const user = await getCurrentUser();
    dispatch(setUser(user));
    localStorage.setItem('user', JSON.stringify(user));
    const roles = await getRoles();
    const permissions = await getPermissions();
    localStorage.setItem('roles', JSON.stringify(roles));
    localStorage.setItem('permissions', JSON.stringify(permissions));
  },[dispatch]);

  useEffect(() => {
    getUser();
  },[getUser]);

  return (
    <>
      {isLogged && <Header />}
      <AppWrapper style={{ height: isLogged ? 'calc(100% - 61px)' : '' }}>
        <ReactHotToast>
          <Toaster
            position={"top-right"}
            toastOptions={{ className: "react-hot-toast" }}
          />
        </ReactHotToast>
        <Routes>
          {isLogged && routes.map((item, index) => <Route key={index} path={item.path} element={item.element} render={item.render}/>)}
          {!isLogged && unauthorizedRoutes.map((item, index) => <Route key={index} path={item.path} element={item.element} render={item.render}/>)}
        </Routes>
      </AppWrapper>
    </>
  )
}

export default App;
