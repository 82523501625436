
import instance from "./api";

const fetchSportsByProjectID = async (projectID) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/projects/${projectID}/sports`;
  const res = await instance.get(endpoint);
  return res.data;
};

const fetchAllSports = async () => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/sports`;
  const res = await instance.get(endpoint);
  return res.data;
};

const enableSport = async (projectID, sportID) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/projects/${projectID}/sports/${sportID}/enable`;
  const res = await instance.post(endpoint);
  return res.data;
};

const disableSport = async (projectID, sportID) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/projects/${projectID}/sports/${sportID}/disable`;
  const res = await instance.post(endpoint);
  return res.data;
};

export { fetchSportsByProjectID, enableSport, disableSport, fetchAllSports };
