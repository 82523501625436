
import instance from "./api";

const endpoints = {
  events: `${process.env.REACT_APP_API_URL}/api/events`,
  event: (id) => `${process.env.REACT_APP_API_URL}/api/events/${id}`,
  projects: `${process.env.REACT_APP_API_URL}/api/projects`,
  getPrediction: (projectId, eventId) =>
    `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/events/${eventId}/prediction`,
  setPrediction: (projectId, eventId) =>
    `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/events/${eventId}/prediction`,
};

const fetchEvents = async (params) => {
  const queryParams = params
    ? params.map((x) => `${x.key}=${x.value}`).join("&")
    : "";
  const res = await instance.get(
    `${endpoints.events}${queryParams ? `?${queryParams}` : ""}`
  );
  return res.data;
};

const editEvent = async (id, params) => {
  const { competitorOneWinChance, place } = params;
  await instance.put(endpoints.event(id), {
    competitorOneWinChance,
    place,
  });
};

const getPrediction = async (projectId, eventId) => {
  const res = await instance.get(endpoints.getPrediction(projectId, eventId));
  return res.data;
};

const setPrediction = async (projectId, eventId, data) => {
  await instance.post(endpoints.setPrediction(projectId, eventId), data);
};

export {
  fetchEvents,
  editEvent,
  getPrediction,
  setPrediction,
};
