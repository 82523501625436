import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { fetchPointsTableCompetitions } from "../../../api/pointsTable";
import hasPermissions from "../../../utils/hasPermissions";
import { PERMISSIONS } from "../../../utils/constants";
import { CompetitionOptionsEditor } from "../competitionOptionsEditor";

const createColumns = (handleOpenDialog) => {
  return [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "league",
      headerName: "League",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Competition",
      flex: 2,
    },
    {
      field: "urlSegment",
      headerName: "Url",
      flex: 2,
      sortable: false,
    },

    {
      field: "actions",
      headerName: "Actions",
      flex: 1.2,
      sortable: false,
      renderCell: (row) => (
        <Button
          variant="contained"
          color="primary"
          disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_STATISTICS)}
          onClick={() => handleOpenDialog(row.row)}
        >
          Edit
        </Button>
      ),
    },
  ];
};

export default function CompetitionTable({ projectId, sport, version }) {
  const [competitions, setCompetitions] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [isCompetitionEditorOpened, setIsCompetitionEditorOpened] =
    useState(false);
  const [selectedCompetitionId, setSelectedCompetitionId] = useState(null);
  const [sortOptions, setSortOptions] = useState([
    { field: "name", sort: "asc" },
  ]);
  const [filters, setFilters] = useState({
    league: "",
    name: "",
    urlSegment: "",
  });
  const [tempFilters, setTempFilters] = useState({
    league: "",
    name: "",
    urlSegment: "",
  });

  const createParams = useCallback(() => {
    const filterParams = [];
    Object.keys(filters).forEach((key) => {
      if (!filters[key]) return;
      filterParams.push({ key, value: filters[key] });
    });
    const pageParams = [
      { key: "take", value: pageSize },
      { key: "skip", value: page * pageSize },
    ];
    const sortParams = [
      { key: "sortBy", value: sortOptions?.[0]?.field || "name" },
      { key: "sortDirection", value: sortOptions?.[0]?.sort || "asc" },
    ];
    const sourceParams = [
      { key: "source", value: sport.source },
      { key: "version", value: version },
    ];
    return [...filterParams, ...pageParams, ...sortParams, ...sourceParams];
  }, [filters, page, pageSize, sortOptions, sport, version]);

  const fetchCompetitionList = useCallback(async () => {
    if (!projectId) return;
    const queryParams = createParams();
    const competitions = await fetchPointsTableCompetitions(
      projectId,
      sport.id,
      queryParams
    );
    setCompetitions(competitions.data);
    setTotal(competitions.total);
  }, [createParams, projectId, sport]);

  const handleFiltersChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setTempFilters({ ...tempFilters, [name]: value });
    },
    [tempFilters]
  );

  const handleOpenDialog = useCallback((item) => {
    setSelectedCompetitionId(item.id);
    setIsCompetitionEditorOpened(true);
  }, []);

  const handleSearchClick = useCallback(() => {
    setPage(0);
    setFilters(tempFilters);
  }, [tempFilters]);

  const handleSortModelChange = useCallback((sortModel) => {
    setPage(0);
    setSortOptions([...sortModel]);
  }, []);

  const handleResetFilters = useCallback(async () => {
    setFilters({ league: "", name: "", urlSegment: "" });
    setTempFilters({
      league: "",
      name: "",
      urlSegment: "",
    });
    setPage(0);
    await fetchCompetitionList();
  }, [fetchCompetitionList]);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPage(0);
    setPageSize(newPageSize);
  }, []);

  const handleCompetitionEditorClose = useCallback(() => {
    setIsCompetitionEditorOpened(false);
  }, []);

  useEffect(() => {
    fetchCompetitionList();
  }, [fetchCompetitionList]);

  const columns = useMemo(() => {
    return createColumns(handleOpenDialog);
  }, [handleOpenDialog]);
  return (
    <div>
      <CompetitionOptionsEditor
        open={isCompetitionEditorOpened}
        id={selectedCompetitionId}
        onClose={handleCompetitionEditorClose}
      />
      {competitions && (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              height: "100px",
            }}
          >
            filter by
            <TextField
              name="league"
              variant="outlined"
              size="small"
              label="League"
              value={tempFilters.league}
              onChange={(event) => handleFiltersChange(event)}
            />
            <TextField
              name="name"
              variant="outlined"
              size="small"
              label="Competition"
              value={tempFilters.name}
              onChange={(event) => handleFiltersChange(event)}
            />
            <TextField
              name="urlSegment"
              variant="outlined"
              size="small"
              label="Url"
              value={tempFilters.urlSegment}
              onChange={(event) => handleFiltersChange(event)}
            />
            <Button variant="contained" onClick={() => handleSearchClick()}>
              Search
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleResetFilters()}
            >
              Reset
            </Button>
          </div>
          <Box sx={{ height: "700px", width: "100%" }}>
            <DataGrid
              rows={competitions}
              columns={columns}
              rowCount={total}
              pageSize={pageSize}
              page={page}
              paginationMode="server"
              onPageChange={setPage}
              onPageSizeChange={handlePageSizeChange}
              rowsPerPageOptions={[25, 50, 100]}
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
              disableSelectionOnClick
              disableColumnFilter
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
        </div>
      )}
    </div>
  );
}
