import { Box, Button, Dialog, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import hasPermissions from "../../utils/hasPermissions";
import { PERMISSIONS } from "../../utils/constants";
import EditContentTabs from "../../components/editContentTabs";
import { getContent, setContent } from "../../api/content";
import { useSelector } from "react-redux";
import { getCompetitions } from "../../api/competitions";

const createColumns = (handleOpenDialog) => {
  return [
    {
      field: "id",
      headerName: "Id",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "urlSegment",
      headerName: "Url segment",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            padding: '5px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_COMPETITIONS)}
            onClick={() => handleOpenDialog(row)}
          >
            Edit
          </Button>
        </Box>
      ),
    },
  ];
};





export default function Competitions() {
  const projectId = useSelector((state) => state.project.currentProjectId);
  const source = useSelector((state) => state.source.currentSource);
  const [competitions, setCompetitions] = useState(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [dialog, setDialog] = useState(false);
  const [currentCompetition, setCurrentCompetition] = useState(null);
  const [pageSize, setPageSize] = useState(25);
  const [filters, setFilters] = useState({
    urlSegment: "",
    name: "",
  });
  const [tempFilters, setTempFilters] = useState({
    urlSegment: "",
    name: "",
  });

  const closeDialog = () => {
    setDialog(false);
  };

  const createParams = useCallback(() => {
    const filterParams = [];
    Object.keys(filters).forEach((key) => {
      if (!filters[key]) return;
      filterParams.push({ key, value: filters[key] });
    });
    const pageParams = [
      { key: "take", value: pageSize },
      { key: "skip", value: page * pageSize },
    ];
    const sourceParams = [{ key: "source", value: source }];
    return [...filterParams, ...pageParams, ...sourceParams];
  }, [filters, page, pageSize, source]);

  const handleFiltersChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setTempFilters({ ...tempFilters, [name]: value });
    },
    [tempFilters]
  );

  const handleSearchClick = useCallback(() => {
    setPage(0);
    setFilters(tempFilters);
  }, [tempFilters]);

  const getCompetitionList = useCallback(async () => {
    if (!source) {
      return;
    }
    const queryParams = createParams();
    const res = await getCompetitions(queryParams);
    setCompetitions(res.data);
    setTotal(res.total);
  }, [createParams, source]);

  const handleResetFilters = useCallback(async () => {
    setFilters({
      urlSegment: "",
      name: "",
    });
    setTempFilters({
      urlSegment: "",
      name: "",
    });
    setPage(0);
  }, []);

  const handleOpenDialog = useCallback((item) => {
    setCurrentCompetition(item);
    setDialog(true);
  }, []);

  const columns = useMemo(() => {
    return createColumns(handleOpenDialog);
  }, [handleOpenDialog]);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPage(0);
    setPageSize(newPageSize);
  }, []);

  const contentKeys = [
    { title: "Teams", contentPostfix: "Competitors" },
    { title: "Players", contentPostfix: "Players" },
  ];

  const handleGetCatalogContent = useCallback(
    async (contentKey) => {
      const competition = currentCompetition || {};
      const data = await getContent(projectId, 'Competition', `${competition.id}-${contentKey}`);
      return data;
    },
    [projectId, currentCompetition]
  );

  const handleSetCatalogContent = useCallback(
    async (contentKey, content) => {
      const competition = currentCompetition || {};
      const data = await setContent(projectId, 'Competition', `${competition.id}-${contentKey}`, content);
      return data;
    },
    [projectId, currentCompetition]
  );


  useEffect(() => {
    getCompetitionList();
  }, [getCompetitionList])

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
      <h1>Competitions</h1>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "12px",
          height: "100px",
        }}
      >
        filter by
        <TextField
          name="name"
          variant="outlined"
          size="small"
          label="Name"
          value={tempFilters.name}
          onChange={(event) => handleFiltersChange(event)}
        />
        <TextField
          name="urlSegment"
          variant="outlined"
          size="small"
          label="Url Segment"
          value={tempFilters.urlSegment}
          onChange={(event) => handleFiltersChange(event)}
        />
        <Button variant="contained" onClick={() => handleSearchClick()}>
          Search
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => handleResetFilters()}
        >
          Reset
        </Button>
      </div>
      {competitions && <Box sx={{ height: "700px", width: "100%" }}>
        <DataGrid
          getRowHeight={() => 'auto'}
          rows={competitions}
          columns={columns}
          rowCount={total}
          pageSize={pageSize}
          page={page}
          paginationMode="server"
          onPageChange={setPage}
          onPageSizeChange={handlePageSizeChange}
          rowsPerPageOptions={[25, 50, 100]}
          disableSelectionOnClick
          disableColumnFilter
          experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>}
      {currentCompetition && (
        <Dialog fullScreen open={dialog} onClose={closeDialog}>
          <Box>
            <EditContentTabs
              item={{ contentKey: '' }}
              keys={contentKeys}
              closeDialog={closeDialog}
              getContent={handleGetCatalogContent}
              setContent={handleSetCatalogContent}
            />
          </Box>
        </Dialog>
      )}
    </div>
  );
}
