
import instance from "./api";

const endpoints = {
  venues: `${process.env.REACT_APP_API_URL}/api/venues`,
};

const fetchAllVenues = async (params) => {
  const queryParams = params
    ? params.map((x) => `${x.key}=${x.value}`).join("&")
    : "";
  const res = await instance.get(
    `${endpoints.venues}${queryParams ? `?${queryParams}` : ""}`
  );
  return res.data;
};

export { fetchAllVenues };
