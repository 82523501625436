import { Button } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import {
  fetchCompetitionsOptions,
  setCompetitionsOptions,
} from "../../../api/pointsTable";

export function CompetitionOptionsEditor(props) {
  const { id, onClose, open } = props;
  const currentProjectId = useSelector(
    (state) => state.project.currentProjectId
  );
  const [competitionOptions, setCompetitionOptions] = useState({
    drawFactor: "",
    defeatFactor: "",
    winFactor: "",
    noResultFactor: "",
  });

  const isEmpty = (value) => {
    if (value === null || value === "" || value === undefined) {
      return true;
    }
    return false;
  };

  const isDisabled = useMemo(() => {
    let disabled = false;
    Object.values(competitionOptions).forEach((item) => {
      if (isEmpty(item)) {
        disabled = true;
      }
    });
    return disabled;
  }, [competitionOptions]);

  const fetchData = useCallback(async () => {
    if (!currentProjectId) {
      return;
    }
    setCompetitionOptions({
      drawFactor: "",
      defeatFactor: "",
      winFactor: "",
      noResultFactor: "",
    });
    const options = await fetchCompetitionsOptions(currentProjectId, id);
    setCompetitionOptions(options);
  }, [currentProjectId, id]);

  const handleChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setCompetitionOptions({ ...competitionOptions, [name]: value });
    },
    [competitionOptions]
  );

  const handleSave = useCallback(async () => {
    await setCompetitionsOptions(currentProjectId, id, {
      ...competitionOptions,
    });
    onClose();
  }, [currentProjectId, id, onClose, competitionOptions]);

  useEffect(() => {
    if (!open) {
      return;
    }
    fetchData();
  }, [open, fetchData]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Competition Options</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: "400px",
        }}
      >
        <TextField
          sx={{ width: "100%" }}
          name="winFactor"
          label="Win PTS"
          size="small"
          variant="standard"
          type="number"
          value={competitionOptions.winFactor}
          required
          onChange={handleChange}
        />
        <TextField
          sx={{ width: "100%" }}
          name="drawFactor"
          label="Draw PTS"
          size="small"
          variant="standard"
          type="number"
          value={competitionOptions.drawFactor}
          required
          onChange={handleChange}
        />
        <TextField
          sx={{ width: "100%" }}
          name="defeatFactor"
          label="Defeat PTS"
          size="small"
          variant="standard"
          type="number"
          value={competitionOptions.defeatFactor}
          required
          onChange={handleChange}
        />

        <TextField
          sx={{ width: "100%" }}
          name="noResultFactor"
          label="No Result PTS"
          size="small"
          variant="standard"
          type="number"
          value={competitionOptions.noResultFactor}
          required
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={isDisabled}
        >
          Save
        </Button>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
