import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import projectSlice from "./slices/projectSlice";
import sourceSlice from "./slices/sourceSlice";
import sportsSlice from "./slices/sportsSlice";

const rootReducer = combineReducers({
  project: projectSlice,
  source: sourceSlice,
  sports: sportsSlice,
  auth: authSlice,
});

export const store = configureStore({
  reducer: rootReducer,
});
