import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { fetchAllSports } from "../../../api/sports";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  createPredictionTable,
  fetchPredictionTable,
  putPredictionTable,
} from "../../../api/predictionTableList";
import { CompetitionsControl } from "../competitionsControl";
import toast from "react-hot-toast";

export default function PredictionTable({
  projectId,
  id,
  periods,
  onCloseDialog,
  handleGetPredictionTableList,
  source,
}) {
  const [prediction, setPrediction] = useState(null);
  const [errorList, setErrorList] = useState({});
  const [hash, setHash] = useState([]);
  const [sports, setSports] = useState(null);

  const setupPrediction = useCallback(
    async (id) => {
      if (id) {
        const prediction = await fetchPredictionTable(projectId, id);
        const hashTable = prediction.competitions.reduce((a, c) => {
          a[`1-${c.sportId}`] = { level: 1, id: c.sportId, parentId: null };
          a[`2-${c.leagueId}`] = {
            level: 2,
            id: c.leagueId,
            parentId: c.sportId,
          };
          a[`3-${c.id}`] = { level: 3, id: c.id, parentId: c.leagueId };
          return a;
        }, {});
        setHash(hashTable);
        Object.keys(prediction).forEach((item) => {
          if (!prediction[item]) {
            prediction[item] = "";
          }
        });
        setPrediction(prediction);
      } else {
        const prediction = {
          competitions: [],
          count: "",
          days: "",
          name: "",
          period: "",
          projectId,
        };
        setPrediction(prediction);
      }
    },
    [projectId]
  );

  const fetchSports = useCallback(async () => {
    const sports = await fetchAllSports();
    setSports(sports);
  }, []);

  const uncheckAllChildren = useCallback(
    (level, id) => {
      const key = `${level}-${id}`;
      const keys = Object.values(hash)
        .filter((item) => item.level === level + 1 && item.parentId === id)
        .reduce(
          (a, item) => [...uncheckAllChildren(level + 1, item.id), ...a],
          []
        );
      const result = [...keys, key];
      return result;
    },
    [hash]
  );

  const handleHashChange = useCallback(
    (items, state) => {
      let newHash = { ...hash };
      items.forEach(({ level, id, parentId }) => {
        const key = `${level}-${id}`;
        if (!state) {
          const keys = uncheckAllChildren(level, id);
          keys.forEach((key) => delete newHash[key]);
        } else {
          newHash = { ...newHash, [key]: { level, id, parentId } };
        }
      });
      setHash(newHash);
    },
    [hash, uncheckAllChildren]
  );

  const isFormValid = useCallback(() => {
    let validate = true;
    const form = prediction;
    let errors = { ...errorList };
    Object.keys(form).forEach((item) => {
      if (item === "count") {
        if (form.count) {
          if (form.count < 1 || form.count > 300) {
            validate = false;
            errors = {
              ...errors,
              [item]: `Should be between 1 and 300`,
            };
          }
        }
      } else if (item === "days") {
        if (form.days) {
          if (form.days < 1 || form.days > 365) {
            validate = false;
            errors = {
              ...errors,
              [item]: `Should be between 1 and 365`,
            };
          }
        }
      } else {
        if (!form[item]) {
          validate = false;
          errors = { ...errors, [item]: `Can't be empty` };
        }
      }
    });
    setErrorList(errors);
    return validate;
  }, [prediction, errorList]);

  const disabledDays = useMemo(() => {
    if (!prediction || !prediction.period) return false;
    return (prediction.period & 4) !== 4;
  }, [prediction]);

  const handleButtonClick = useCallback(async () => {
    const newPrediction = { ...prediction, source };
    Object.keys(newPrediction).forEach((key) => {
      if (key === "competitions") {
        newPrediction.competitions = Object.values(hash)
          .filter((value) => value.level === 3)
          .map((item) => item.id);
      }
      if (!newPrediction[key]) {
        newPrediction[key] = null;
      }
    });
    if (disabledDays) {
      newPrediction.days = null;
    }
    try {
      if (id) {
        if (!isFormValid()) return;
        await putPredictionTable(projectId, id, newPrediction);
      } else {
        if (!isFormValid()) return;
        await createPredictionTable(projectId, newPrediction);
      }
      handleGetPredictionTableList();
      onCloseDialog();
    } catch (res) {
      res.response.data.errors.forEach((item) => toast.error(item.message));
    }
  }, [
    disabledDays,
    prediction,
    projectId,
    handleGetPredictionTableList,
    id,
    onCloseDialog,
    isFormValid,
    hash,
    source,
  ]);

  const handlePredictionChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      const newPrediction = { ...prediction, [name]: value };
      if (name === "period" && (value & 4) !== 4) {
        newPrediction.days = "";
      }
      setPrediction(newPrediction);
    },
    [prediction]
  );

  const handleClearError = useCallback(
    (event) => {
      const { name } = event.target;
      setErrorList({ ...errorList, [name]: "" });
    },
    [errorList]
  );

  useEffect(() => {
    setupPrediction(id);
    fetchSports();
  }, [id, projectId, setupPrediction, fetchSports]);

  return (
    <div style={{ padding: "25px" }}>
      {prediction && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "12px",
              height: "100px",
            }}
          >
            <TextField
              name="name"
              variant="outlined"
              size="small"
              label="Name"
              value={prediction.name}
              error={errorList.name ? true : false}
              helperText={errorList.name || ""}
              onFocus={(event) => handleClearError(event)}
              onChange={(event) => handlePredictionChange(event)}
            />
            <FormControl sx={{ width: 195 }}>
              <TextField
                select
                value={prediction.period}
                label="Periods"
                name="period"
                variant="outlined"
                size="small"
                error={errorList.period ? true : false}
                helperText={errorList.period || ""}
                onChange={(event) => {
                  handlePredictionChange(event);
                  handleClearError(event);
                }}
              >
                {periods &&
                  periods.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </TextField>
            </FormControl>
            <TextField
              name="count"
              variant="outlined"
              size="small"
              type={"number"}
              label="Count"
              error={errorList.count ? true : false}
              helperText={errorList.count || ""}
              onFocus={(event) => handleClearError(event)}
              value={prediction.count}
              onChange={(event) => handlePredictionChange(event)}
            />
            <TextField
              name="days"
              variant="outlined"
              type={"number"}
              size="small"
              label="Days"
              disabled={disabledDays}
              error={errorList.days ? true : false}
              helperText={errorList.days || ""}
              onFocus={(event) => handleClearError(event)}
              value={prediction.days}
              onChange={(event) => handlePredictionChange(event)}
            />
          </div>
          <Button
            variant="contained"
            color={"success"}
            onClick={handleButtonClick}
          >
            {"Save"}
          </Button>
        </div>
      )}
      {sports &&
        prediction &&
        sports.map((item) => (
          <CompetitionsControl
            key={item.id}
            item={item}
            hash={hash}
            level={1}
            handleHashChange={handleHashChange}
            parentId={null}
            source={source}
          />
        ))}
    </div>
  );
}
