import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import { useCallback, useMemo, useState } from "react";
import EditContentTabs from "../../editContentTabs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  fetchOddsCenterContent,
  setOddsCenterContent,
} from "../../../api/oddsCenter";
import PrivateComponent from "../../../providers/PrivateComponent/PrivateComponent";
import { PERMISSIONS } from "../../../utils/constants";

export function OddsNode({ projectId, sport, item, level }) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [dialog, setDialog] = useState(false);

  const openDialog = () => {
    setDialog(true);
  };
  const closeDialog = () => {
    setDialog(false);
  };

  const padding = useMemo(() => {
    return 2 * level;
  }, [level]);

  const contentKeys = useMemo(() => {
    const keys = [
      { title: "Live", contentPostfix: 1 << 1 },
      { title: "Upcoming", contentPostfix: 1 << 2 },
    ];

    return keys;
  }, []);

  const toggleCollaps = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  const handleGetContent = useCallback(async (contentKey) => {
    const data = await fetchOddsCenterContent(projectId, sport.id, contentKey);
    return data;
  }, [projectId, sport]);

  const handleSetContent = useCallback(async (contentKey, content) => {
    const data = await setOddsCenterContent(projectId, sport.id, contentKey, content);
    return data;
  }, [projectId, sport]);

  return (
    <div>
      <Dialog fullScreen open={dialog} onClose={closeDialog}>
        <EditContentTabs
          item={item}
          keys={contentKeys}
          closeDialog={closeDialog}
          getContent={handleGetContent}
          setContent={handleSetContent}
        />
      </Dialog>
      <List disablePadding>
        <Divider />
        <ListItemButton
          sx={{
            paddingLeft: padding,
            background: "#f9f9f9",
            display: "flex",
            justifyContent: "space-between",
          }}
          onClick={toggleCollaps}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "25px" }}>
              {item.children.length ? (
                isCollapsed ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )
              ) : (
                <div style={{ paddingRight: "24px" }}></div>
              )}
              {item.name}
            </div>
            <PrivateComponent permission={PERMISSIONS.ALLOW_WRITE_ODDS_CENTER}>
              <Button variant="outlined" color={"warning"} onClick={openDialog}>
                Edit
              </Button>
            </PrivateComponent>
          </div>
        </ListItemButton>
        <Divider />
      </List>
      <Collapse in={isCollapsed}>
        {isCollapsed &&
          item.children.map((children) => (
            <OddsNode
              key={children.contentKey}
              projectId={projectId}
              sport={sport}
              item={children}
              level={level + 1}
            />
          ))}
      </Collapse>
    </div>
  );
}
