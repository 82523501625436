import instance from "./api";

const userLogin = async (data) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/login`;
  const res = await instance.post(
    `${endpoint}`,
    data
  );
  return res.data;
};

export { userLogin };
