import { TeamsBlock } from "./teamsBlock.jsx";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState } from "react";
import { PlayersBlock } from "./playersBlock.jsx/index.jsx";
import PrivateComponent from "../../providers/PrivateComponent/PrivateComponent.jsx";
import { PERMISSIONS } from "../../utils/constants.js";

export function TeamsAndPlayers() {
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const keys = [
    { title: "Teams", value: "1" },
    { title: "Players", value: "2" },
  ];

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          centered
        >
          {keys.map((item, index) => (
            <Tab key={index} label={item.title} value={item.value} />
          ))}
        </TabList>
      </Box>
      <PrivateComponent permission={PERMISSIONS.ALLOW_READ_COMPETITORS}>
        <TabPanel value={"1"}>
          <TeamsBlock />
        </TabPanel>
      </PrivateComponent>
      <PrivateComponent permission={PERMISSIONS.ALLOW_READ_PLAYERS}>
        <TabPanel value={"2"}>
          <PlayersBlock />
        </TabPanel>
      </PrivateComponent>
    </TabContext>
  );
}
