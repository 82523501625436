import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";

import { fetchAllLeaguesBySport } from "../../../api/leagues";
import { getAllCompetitionsByLeague } from "../../../api/competitions";
import { useCallback, useEffect, useMemo, useState } from "react";

export function CompetitionsControl({
  item,
  hash,
  level,
  handleHashChange,
  parentId,
  source,
}) {
  const [data, setData] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isInited, setIsInited] = useState(false);

  const padding = useMemo(() => {
    return 2 * level;
  }, [level]);

  const isChecked = useMemo(
    () => !!hash[`${level}-${item.id}`],
    [hash, level, item]
  );

  const isAllChecked = useMemo(
    () =>
      data.length ===
      data.filter((item) => hash[`${level + 1}-${item.id}`]).length,
    [data, hash, level]
  );

  const getCompetitions = useCallback(async () => {
    const params = [
      { key: "sportId", value: item.sportId },
      { key: "leagueId", value: item.id },
      { key: "source", value: source },
    ];

    const competitions = await getAllCompetitionsByLeague(params);
    setData(competitions);
  }, [item, source]);

  const fetchLeagues = useCallback(async () => {
    const leagues = await fetchAllLeaguesBySport([
      {
        key: "sportId",
        value: item.id,
      },
      { key: "source", value: source },
    ]);
    setData(leagues);
  }, [item, source]);

  const initChildren = useCallback(() => {
    if (level === 1) {
      fetchLeagues();
    } else if (level === 2) {
      getCompetitions();
    }
  }, [getCompetitions, fetchLeagues, level]);

  const toggleCollaps = useCallback(() => {
    initChildren();
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed, initChildren]);

  const toggleChecked = useCallback(() => {
    handleHashChange([{ level, id: item.id, parentId }], !isChecked);
    setIsCollapsed(!isChecked);
  }, [isChecked, item, level, handleHashChange, parentId]);

  const handleCheckedAll = useCallback(() => {
    handleHashChange(
      data.map(({ id }) => ({
        level: level + 1,
        id,
      })),
      !isAllChecked
    );
  }, [data, isAllChecked, handleHashChange, level]);

  const handleChildrenChange = useCallback(
    (items, state) => {
      if (!state) return handleHashChange(items, state);
      const newItems = [...items, { level, id: item.id, parentId }];
      handleHashChange(newItems, state);
    },
    [handleHashChange, level, parentId, item.id]
  );

  useEffect(() => {
    if (isInited) return;
    if (isChecked) {
      initChildren();
      setIsCollapsed(isChecked);
    }
    setIsInited(true);
  }, [initChildren, isChecked, setIsInited, isInited]);

  return (
    <div>
      <List disablePadding>
        <Divider />
        <ListItemButton
          sx={{
            paddingLeft: padding,
            background: "#f9f9f9",
            display: "flex",
            justifyContent: "space-between",
          }}
          onClick={toggleCollaps}
        >
          <FormControlLabel
            label={item.name}
            control={
              <Checkbox
                name={item.id.toString()}
                checked={isChecked}
                onChange={toggleChecked}
              />
            }
          />
          {isCollapsed && level === 2 && (
            <FormControlLabel
              label="Enable all"
              sx={{
                fontSize: "14px",
              }}
              onClick={(e) => e.stopPropagation()}
              control={
                <Checkbox
                  name={item.id.toString()}
                  sx={{
                    fontSize: "14px",
                  }}
                  checked={isAllChecked}
                  onChange={handleCheckedAll}
                />
              }
            />
          )}
        </ListItemButton>
      </List>
      {level !== 3 && (
        <Collapse in={isCollapsed}>
          {isCollapsed &&
            data.map((children) => (
              <CompetitionsControl
                key={children.id}
                hash={hash}
                item={children}
                level={level + 1}
                handleHashChange={handleChildrenChange}
                parentId={item.id}
                source={source}
              />
            ))}
        </Collapse>
      )}
    </div>
  );
}
