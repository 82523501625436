
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { setUser } from '../../store/slices/authSlice'
import globalRouter from '../../utils/globalRouter.ts';


function AuthObserver({ children }) {

  const navigate = useNavigate();
  globalRouter.navigate = navigate;

  const [isInit, setIsInit] = useState(true);

  const user = useSelector(state => state.auth.user);
  const savedUser = JSON.parse(localStorage.getItem('user'));

  const dispatch = useDispatch();
  const dispatchLoginFromLocalStorage = useCallback(() => {
    dispatch(setUser(savedUser));
  }, [dispatch, savedUser]);

  const inLocalNotInStore = !!(savedUser && !user);

  useEffect(() => {
    // Auto login from local storage
    if (inLocalNotInStore && isInit) {
      dispatchLoginFromLocalStorage();
      setIsInit(false);
    }
  }, [inLocalNotInStore, isInit, dispatchLoginFromLocalStorage, setIsInit]);

  return children
}

export default AuthObserver
