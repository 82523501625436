import { Button } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { getPrediction, setPrediction } from "../../api/matchPredictions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

export function UrlsEditor(props) {
  const { id, onClose, open } = props;
  const [predictionUrl, setPredictionUrl] = useState('');
  const currentProjectId = useSelector(
    (state) => state.project.currentProjectId
  );

  const fetchData = useCallback(async () => {
    if (!currentProjectId) {
      return;
    }

    const prediction = await getPrediction(currentProjectId, id);
    setPredictionUrl(prediction?.predictionUrl);
  }, [currentProjectId, id]);

  const handleChange = useCallback(({ target: { value } }) => {
    setPredictionUrl(value);
  }, []);

  const handleSave = useCallback(async () => {
    await setPrediction(currentProjectId, id, { predictionUrl });
    onClose();
  }, [currentProjectId, id, onClose, predictionUrl]);

  useEffect(() => {
    if (!open) {
      return;
    }

    setPredictionUrl('');
    fetchData();
  }, [open, fetchData]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Prediction URL</DialogTitle>
      <DialogContent>
        <TextField
          sx={{ width: "400px" }}
          name="predict"
          label="Predict URL"
          size="small"
          variant="standard"
          value={predictionUrl}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save
        </Button>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
