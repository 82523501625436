import * as React from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

export default function CommonSelect({
  value,
  items,
  label,
  name,
  variant, // outlined, filled, standart
  size, // small, normal
  onChange,
}) {
  return (
    <FormControl
      sx={{
        width: 195,
        "& input": {
          textAlign: "center",
        },
      }}
    >
      <TextField
        select
        value={value}
        label={label}
        name={name}
        variant={variant}
        size={size}
        onChange={(event) => {
          onChange(event);
        }}
      >
        {items.map((item, index) => (
          <MenuItem key={index} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
}
