import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  fetchPredictionTableList,
  deletePredictionTable,
  fetchPeriods,
} from "../../../api/predictionTableList";
import { IoMdCopy } from "react-icons/io";
import PredictionTable from "../predictionTable";
import ConfirmationDialog from "../../common/confirmationDialog";
import { Link } from "@mui/material";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import PrivateComponent from "../../../providers/PrivateComponent/PrivateComponent";
import { PERMISSIONS } from "../../../utils/constants";
import hasPermissions from "../../../utils/hasPermissions";

const ShortCode = ({ code, onCopyClick }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: "5px",
    }}
  >
    <label>{code}</label>
    <IoMdCopy
      style={{ height: "20px" }}
      size={30}
      cursor={"pointer"}
      onClick={() => onCopyClick(code)}
    />
  </div>
);

const createColumns = (handleDelete, handleEdit, handleClipboardClick) => {
  return [
    {
      field: "name",
      headerName: "Name",
      flex: 0.5,
    },
    {
      field: "period",
      headerName: "Period",
      flex: 0.5,
    },
    {
      field: "count",
      headerName: "Count",
      flex: 0.2,
    },
    {
      field: "days",
      headerName: "Days",
      flex: 0.2,
    },
    {
      field: "predictionsCode",
      headerName: "Predictions Code",
      flex: 0.4,
      renderCell: (row) => (
        <ShortCode
          code={row.row.predictionsCode}
          onCopyClick={handleClipboardClick}
        />
      ),
    },
    {
      field: "oddsCode",
      headerName: "Odds Code",
      flex: 0.4,
      renderCell: (row) => (
        <ShortCode code={row.row.oddsCode} onCopyClick={handleClipboardClick} />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.3,
      renderCell: (row) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          <Button
            variant="contained"
            color="primary"
            disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_PREDICTION_TABLE)}
            onClick={() => handleEdit(row.row.id)}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_PREDICTION_TABLE)}
            onClick={() => handleDelete(row.row)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];
};

export default function PredictionTableList() {
  const [predictionTableList, setPredictionTableList] = useState(null);
  const [total, setTotal] = useState(0);
  const [dialog, setDialog] = useState(false);
  const [activeTable, setActiveTable] = useState(null);
  const [periods, setPeriods] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortOptions, setSortOptions] = useState([
    { field: "name", sort: "desc" },
    { field: "period", sort: "desc" },
  ]);

  const projectId = useSelector((state) => state.project.currentProjectId);
  const currentSource = useSelector((state) => state.source.currentSource);

  const openDialog = () => {
    setDialog(true);
  };
  const closeDialog = () => {
    setDialog(false);
  };

  const createParams = useCallback(() => {
    const pageParams = [
      { key: "take", value: pageSize },
      { key: "skip", value: page * pageSize },
    ];
    const sortParams = [
      { key: "sortBy", value: sortOptions?.[0]?.field || "date" },
      { key: "sortDirection", value: sortOptions?.[0]?.sort || "desc" },
    ];
    const sourceParams = [{ key: "source", value: currentSource }];
    return [...pageParams, ...sortParams, ...sourceParams];
  }, [page, pageSize, sortOptions, currentSource]);

  const getPredictionTableList = useCallback(async () => {
    if (!currentSource || !projectId) {
      return;
    }
    const params = createParams();
    const list = await fetchPredictionTableList(projectId, params);
    setPredictionTableList(list.data);
    setTotal(list.total);
  }, [projectId, currentSource, createParams]);

  const handleDelete = useCallback(
    (item) => {
      setCurrentItem(item);
      setOpen(true);
    },
    [setCurrentItem, setOpen]
  );

  const handleConfirm = useCallback(async () => {
    await deletePredictionTable(projectId, currentItem.id);
    getPredictionTableList();
    setOpen(false);
  }, [getPredictionTableList, projectId, currentItem]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setCurrentItem(null);
  }, [setOpen, setCurrentItem]);

  const handleEdit = useCallback((id) => {
    setActiveTable(id);
    openDialog();
  }, []);

  const handleCreate = useCallback(() => {
    setActiveTable(null);
    openDialog();
  }, []);

  const getPeriodsList = useCallback(async () => {
    const periods = await fetchPeriods();
    setPeriods(periods);
  }, []);

  const handleClipboardClick = useCallback((code) => {
    navigator.clipboard.writeText(code);
    toast.success("Copied successfully");
  }, []);

  const handleSortModelChange = useCallback((sortModel) => {
    setPage(0);
    setSortOptions([...sortModel]);
  }, []);

  const columns = useMemo(() => {
    return createColumns(handleDelete, handleEdit, handleClipboardClick);
  }, [handleDelete, handleEdit, handleClipboardClick]);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPage(0);
    setPageSize(newPageSize);
  }, []);

  useEffect(() => {
    getPredictionTableList();
    getPeriodsList();
  }, [getPeriodsList, getPredictionTableList]);

  return (
    <div>
      <ConfirmationDialog
        open={open}
        title={"Confirm"}
        content={"Do you want to delete this prediction configuration?"}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
      {predictionTableList && periods && (
        <div>
          <Dialog fullScreen open={dialog} onClose={closeDialog}>
            <AppBar sx={{ position: "relative" }} color="transparent">
              <Toolbar>
                <Button onClick={closeDialog} color="primary">
                  Close
                </Button>
              </Toolbar>
            </AppBar>
            <PredictionTable
              projectId={projectId}
              id={activeTable}
              periods={periods}
              onCloseDialog={closeDialog}
              handleGetPredictionTableList={getPredictionTableList}
              source={currentSource}
            />
          </Dialog>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link href="/match-predictions">
              <Button variant="contained">Back</Button>
            </Link>
            <PrivateComponent permission={PERMISSIONS.ALLOW_WRITE_PREDICTION_TABLE}>
              <Button
                variant="contained"
                onClick={() => handleCreate(projectId, null)}
              >
                Create
              </Button>
            </PrivateComponent>
          </div>

          <Box sx={{ height: "700px", width: "100%", mt: 2 }}>
            <DataGrid
              rows={predictionTableList}
              columns={columns}
              rowCount={total}
              pageSize={pageSize}
              page={page}
              paginationMode="server"
              onPageChange={setPage}
              onPageSizeChange={handlePageSizeChange}
              rowsPerPageOptions={[25, 50, 100]}
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
              disableSelectionOnClick
              disableColumnFilter
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
        </div>
      )}
    </div>
  );
}
