import { useEffect, useState, useCallback } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CustomField from "./customField";

export default function CustomFieldList({ customFields, onContentChange }) {
  const [localCustomFields, setLocalCustomFields] = useState([]);
  const [newCustomField, setNewLocalCustomFields] = useState({
    name: "",
    value: "",
  });

  const handleLocalCustomFieldsChange = useCallback(
    (customField, index) => {
      const newLocalCustomFields = [...localCustomFields];
      newLocalCustomFields[index] = customField;
      setLocalCustomFields(newLocalCustomFields);
      onContentChange("customFields", newLocalCustomFields);
    },
    [localCustomFields, onContentChange]
  );

  const handleDelete = useCallback(
    (index) => {
      const newLocalCustomFields = [...localCustomFields];
      newLocalCustomFields.splice(index, 1);
      setLocalCustomFields(newLocalCustomFields);
      onContentChange("customFields", newLocalCustomFields);
    },
    [localCustomFields, onContentChange]
  );

  const handleAddLocalField = useCallback(() => {
    if (!newCustomField.name || !newCustomField.value) return;
    const newLocalCustomFields = [...localCustomFields, newCustomField];
    setLocalCustomFields(newLocalCustomFields);
    onContentChange("customFields", newLocalCustomFields);
    setNewLocalCustomFields({ name: "", value: "" });
  }, [newCustomField, localCustomFields, onContentChange]);

  const handleChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setNewLocalCustomFields({ ...newCustomField, [name]: value });
    },
    [newCustomField]
  );

  useEffect(() => {
    setLocalCustomFields([...customFields]);
  }, [customFields]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "35px",
        width: "100%",
      }}
    >
      <h1 style={{ textAlign: "center", margin: "0" }}>Custom Fields</h1>
      {localCustomFields.length > 0 &&
        localCustomFields.map((customField, index) => (
          <CustomField
            key={index}
            customField={customField}
            index={index}
            handleDelete={handleDelete}
            handleLocalCustomFieldsChange={handleLocalCustomFieldsChange}
          />
        ))}
      {localCustomFields.length === 0 && (
        <span>Custom Field list is empty</span>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <TextField
          name="name"
          variant="outlined"
          size="small"
          label="Name"
          fullWidth
          value={newCustomField.name}
          onChange={handleChange}
        />
        <TextField
          name="value"
          variant="outlined"
          size="small"
          label="Value"
          fullWidth
          multiline
          minRows={3}
          value={newCustomField.value}
          onChange={handleChange}
        />
        <Button
          variant="contained"
          color={"primary"}
          onClick={handleAddLocalField}
        >
          Add
        </Button>
      </div>
    </div>
  );
}
