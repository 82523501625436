import { Dialog, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import EditContent from "../editContentTabs/editContent";
import {
  fetchMatchCenterContent,
  setMatchCenterContent,
} from "../../api/matchCenter";
import { fetchAllVenues } from "../../api/venues";
import hasPermissions from "../../utils/hasPermissions";
import { PERMISSIONS } from "../../utils/constants";
import PrivateComponent from "../../providers/PrivateComponent/PrivateComponent";

const createColumns = (handleOpenDialog) => {
  return [
    {
      field: "id",
      headerName: "ID",
      width: 100,
    },
    {
      field: "externalId",
      headerName: "External ID",
      width: 180,
    },
    {
      field: "sport",
      headerName: "Sport",
      width: 150,
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
    },
    {
      field: "country",
      headerName: "Country",
      width: 300,
    },
    {
      field: "city",
      headerName: "City",
      width: 300,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 170,
      sortable: false,
      renderCell: (row) => (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            disabled={!hasPermissions(PERMISSIONS.ALLOW_WRITE_VENUES)}
            onClick={() => handleOpenDialog(row.row)}
          >
            Edit
          </Button>
        </Box>
      ),
    },
  ];
};

export function Venues() {
  const projectId = useSelector((state) => state.project.currentProjectId);
  const currentSource = useSelector((state) => state.source.currentSource);
  const [venues, setVenues] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [currentVenue, setCurrentVenue] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [catalogDialog, setCatalogDialog] = useState(false);
  const [sortOptions, setSortOptions] = useState([
    { field: "name", sort: "asc" },
  ]);

  const [filters, setFilters] = useState({
    id: "",
    name: "",
    sport: "",
    urlSegment: "",
  });
  const [tempFilters, setTempFilters] = useState({
    id: "",
    name: "",
    sport: "",
    urlSegment: "",
  });

  const handleOpenDialog = useCallback((item) => {
    setCurrentVenue(item);
    setDialog(true);
  }, []);

  const closeDialog = () => {
    setDialog(false);
  };

  const handleOpenCatalogDialog = useCallback(() => {
    setCatalogDialog(true);
  }, []);

  const closeCatalogDialog = () => {
    setCatalogDialog(false);
  };

  const createContentKey = useCallback(() => {
    const contentKey = `Venue-${currentVenue.id}`;
    return contentKey;
  }, [currentVenue]);

  const createParams = useCallback(() => {
    const filterParams = [];
    Object.keys(filters).forEach((key) => {
      if (!filters[key]) return;
      filterParams.push({ key, value: filters[key] });
    });
    const pageParams = [
      { key: "take", value: pageSize },
      { key: "skip", value: page * pageSize },
    ];
    const sortParams = [
      { key: "sortBy", value: sortOptions?.[0]?.field || "name" },
      { key: "sortDirection", value: sortOptions?.[0]?.sort || "asc" },
    ];
    const sourceParams = [{ key: "source", value: currentSource }];
    return [...filterParams, ...pageParams, ...sortParams, ...sourceParams];
  }, [filters, page, pageSize, sortOptions, currentSource]);

  const fetchVenueList = useCallback(async () => {
    if (!currentSource) {
      return;
    }
    const queryParams = createParams();
    const venues = await fetchAllVenues(queryParams);
    setVenues(venues.data);
    setTotal(venues.total);
  }, [createParams, currentSource]);

  const handleFiltersChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setTempFilters({ ...tempFilters, [name]: value });
    },
    [tempFilters]
  );

  const handleSearchClick = useCallback(() => {
    setPage(0);
    setFilters(tempFilters);
  }, [tempFilters]);

  const handleSortModelChange = useCallback((sortModel) => {
    setPage(0);
    setSortOptions([...sortModel]);
  }, []);

  const handleResetFilters = useCallback(async () => {
    setFilters({
      name: "",
      id: "",
      sport: "",
      urlSegment: "",
    });
    setTempFilters({
      name: "",
      id: "",
      sport: "",
      urlSegment: "",
    });
    setPage(0);
    await fetchVenueList();
  }, [fetchVenueList]);

  const handlePageSizeChange = useCallback((newPageSize) => {
    setPage(0);
    setPageSize(newPageSize);
  }, []);

  const handleFetchContent = useCallback(
    async (contentKey) => {
      const data = await fetchMatchCenterContent(projectId, 'cricket', contentKey);
      return data;
    },
    [projectId]
  );

  const handleSetContent = useCallback(
    async (contentKey, content) => {
      const data = await setMatchCenterContent(projectId, 'cricket', contentKey, content);
      return data;
    },
    [projectId]
  );

  useEffect(() => {
    async function fetchData() {
      await fetchVenueList();
    }

    fetchData();
  }, [createParams, fetchVenueList]);

  const columns = useMemo(() => {
    return createColumns(handleOpenDialog);
  }, [handleOpenDialog]);

  return (
    <Box sx={{ padding: "25px" }}>
      {venues && (
        <Box>
          <Box sx={{ display: "flex", gap: "25px", padding: "25px 0" }}>
            <h1 style={{ margin: 0 }}>Venues Catalog</h1>
            <PrivateComponent permission={PERMISSIONS.ALLOW_WRITE_VENUES}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleOpenCatalogDialog()}
              >
                Edit Catalog Content
              </Button>
            </PrivateComponent>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
              gap: "12px",
              padding: "20px 0",
            }}
          >
            filter by
            <TextField
              name="id"
              variant="outlined"
              size="small"
              label="ID"
              value={tempFilters.id}
              onChange={(event) => handleFiltersChange(event)}
            />
            <TextField
              name="sport"
              variant="outlined"
              size="small"
              label="Sport"
              value={tempFilters.sport}
              onChange={(event) => handleFiltersChange(event)}
            />
            <TextField
              name="name"
              variant="outlined"
              size="small"
              label="Name"
              value={tempFilters.name}
              onChange={(event) => handleFiltersChange(event)}
            />
            <TextField
              name="urlSegment"
              variant="outlined"
              size="small"
              label="Url"
              value={tempFilters.urlSegment}
              onChange={(event) => handleFiltersChange(event)}
            />
            <Button variant="contained" onClick={() => handleSearchClick()}>
              Search
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => handleResetFilters()}
            >
              Reset
            </Button>
          </Box>
          <Box sx={{ height: "700px", width: "100%" }}>
            <DataGrid
              rows={venues}
              columns={columns}
              rowCount={total}
              pageSize={pageSize}
              page={page}
              paginationMode="server"
              onPageChange={setPage}
              onPageSizeChange={handlePageSizeChange}
              rowsPerPageOptions={[25, 50, 100]}
              sortingMode="server"
              onSortModelChange={handleSortModelChange}
              disableSelectionOnClick
              disableColumnFilter
              experimentalFeatures={{ newEditingApi: true }}
            />
          </Box>
        </Box>
      )
      }
      {
        currentVenue && (
          <Dialog fullScreen open={dialog} onClose={closeDialog}>
            <Box sx={{ padding: "25px" }}>
              <EditContent
                onSave={closeDialog}
                getContent={handleFetchContent}
                setContent={handleSetContent}
                showFields={true}
                contentKey={createContentKey()}
              />
            </Box>
          </Dialog>
        )
      }
      <Dialog fullScreen open={catalogDialog} onClose={closeDialog}>
        <Box sx={{ padding: "25px" }}>
          <EditContent
            onSave={closeCatalogDialog}
            getContent={handleFetchContent}
            setContent={handleSetContent}
            showFields={false}
            contentKey={"Venue"}
          />
        </Box>
      </Dialog>
    </Box >
  );
}
