import instance from "./api";

const getCurrentUser = async () => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/users/me`;
  const res = await instance.get(endpoint);
  return res.data;
};

const getUsers = async (params) => {
  const queryParams = params
    ? params.map((x) => `${x.key}=${x.value}`).join("&")
    : "";
  const endpoint = `${process.env.REACT_APP_API_URL}/api/users${queryParams ? `?${queryParams}` : ""}`;
  const res = await instance.get(endpoint);
  return res.data;
};

const getUser = async (id) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/users/${id}`;
  const res = await instance.get(endpoint);
  return res.data;
};

const setUser = async (data) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/users`;
  const res = await instance.post(endpoint, data);
  return res.data;
};

const editUser = async (data, id) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/users/${id}`;
  const res = await instance.put(endpoint, data);
  return res.data;
};

const deleteUser = async (id) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/users/${id}`;
  const res = await instance.delete(endpoint);
  return res.data;
};

const getPermissions = async () => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/users/permissions`;
  const res = await instance.get(endpoint);
  return res.data;
};

const getRoles = async () => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/users/roles`;
  const res = await instance.get(endpoint);
  return res.data;
};

export { getCurrentUser, getUsers, getUser, deleteUser, editUser, setUser, getPermissions, getRoles };
