export const LOGIN_URL = '/login'

export const PERMISSIONS = {
  ALLOW_READ_COMPETITIONS: 'allow-read-competitions',
  ALLOW_WRITE_COMPETITIONS: 'allow-write-competitions',
  ALLOW_READ_COMPETITORS: 'allow-read-competitors',
  ALLOW_WRITE_COMPETITORS: 'allow-write-competitors',
  ALLOW_READ_EVENTS: 'allow-read-events',
  ALLOW_WRITE_EVENTS: 'allow-write-events',
  ALLOW_READ_LEAGUES: 'allow-read-leagues',
  ALLOW_WRITE_LEAGUES: 'allow-write-leagues',
  ALLOW_READ_LOCATION: 'allow-read-location',
  ALLOW_READ_MATCH_CENTER: 'allow-read-match-center',
  ALLOW_WRITE_MATCH_CENTER: 'allow-write-match-center',
  ALLOW_READ_ODDS_CENTER: 'allow-read-odds-center',
  ALLOW_WRITE_ODDS_CENTER: 'allow-write-odds-center',
  ALLOW_READ_PLAYERS: 'allow-read-players',
  ALLOW_WRITE_PLAYERS: 'allow-write-players',
  ALLOW_READ_PREDICTION_TABLE: 'allow-read-prediction-table',
  ALLOW_WRITE_PREDICTION_TABLE: 'allow-write-prediction-table',
  ALLOW_READ_PROJECTS: 'allow-read-projects',
  ALLOW_WRITE_PROJECTS: 'allow-write-projects',
  ALLOW_READ_SPORTS: 'allow-read-sports',
  ALLOW_WRITE_SPORTS: 'allow-write-sports',
  ALLOW_READ_STATISTICS: 'allow-read-statistics',
  ALLOW_WRITE_STATISTICS: 'allow-write-statistics',
  ALLOW_READ_USERS: 'allow-read-users',
  ALLOW_WRITE_USERS: 'allow-write-users',
  ALLOW_READ_VENUES: 'allow-read-venues',
  ALLOW_WRITE_VENUES: 'allow-write-venues',
  ALLOW_READ_COUNTRIES: 'allow-read-countries',
  ALLOW_WRITE_COUNTRIES: 'allow-write-countries',
};