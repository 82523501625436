
import instance from "./api";

const endpoints = {
  predictionTableList: (projectId) =>
    `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/predictiontables`,
  predictionTable: (projectId, predictionTableId) =>
    `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/predictiontables/${predictionTableId}`,
  periods: `${process.env.REACT_APP_API_URL}/api/predictiontables/periods`,
};

const fetchPredictionTableList = async (projectId, params) => {
  const queryParams = params
    ? params.map((x) => `${x.key}=${x.value}`).join("&")
    : "";
  const res = await instance.get(
    `${endpoints.predictionTableList(projectId)}${
      queryParams ? `?${queryParams}` : ""
    }`
  );
  return res.data;
};

const createPredictionTable = async (projectId, data) => {
  const res = await instance.post(endpoints.predictionTableList(projectId), data);
  return res.data;
};

const fetchPredictionTable = async (projectId, predictionTableId) => {
  const res = await instance.get(
    endpoints.predictionTable(projectId, predictionTableId)
  );
  return res.data;
};

const putPredictionTable = async (projectId, predictionTableId, data) => {
  const res = await instance.put(
    endpoints.predictionTable(projectId, predictionTableId),
    data
  );
  return res.data;
};

const deletePredictionTable = async (projectId, predictionTableId, data) => {
  const res = await instance.delete(
    endpoints.predictionTable(projectId, predictionTableId),
    data
  );
  return res.data;
};

const fetchPeriods = async () => {
  const res = await instance.get(endpoints.periods);
  return res.data;
};

export {
  fetchPredictionTableList,
  createPredictionTable,
  fetchPredictionTable,
  putPredictionTable,
  deletePredictionTable,
  fetchPeriods,
};
