import { createSlice } from '@reduxjs/toolkit'

const authState = {
  accessToken: null,
  user: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState: authState,
  reducers: {
    login(state, action) {
      localStorage.setItem('accessToken', action.payload.token);
      localStorage.setItem('user', JSON.stringify(action.payload));
      state.user = action.payload;
      state.accessToken = action.payload.token;
    },
    setUser(state, action) {
      localStorage.setItem('user', JSON.stringify(action.payload));
      state.user = action.payload;
    },
    logout(state) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('user');
      state.user = null;
      state.accessToken = null;
    },
  },
})

export const { login, setUser, logout, setIsLogged } = authSlice.actions

export default authSlice.reducer
