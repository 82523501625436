import Button from "@mui/material/Button";
import { fetchAllLeaguesBySport } from "../../../../api/leagues";
import { useCallback, useEffect, useState } from "react";
import { ConfigureControl } from "../configureControl";

export default function ConfigureList({
  projectId,
  sport,
  id,
  onCloseDialog,
  onSubmit,
  initialHash,
  setConfiguration,
}) {
  const [hash, setHash] = useState([]);
  const [leagues, setLeagues] = useState(null);

  const fetchLeagues = useCallback(async () => {
    const leagues = await fetchAllLeaguesBySport([
      {
        key: "sportId",
        value: sport.id,
      },
      { key: "source", value: sport.source },
    ]);
    setLeagues(leagues);
  }, [sport]);

  const uncheckAllChildren = useCallback(
    (level, id) => {
      const key = `${level}-${id}`;
      const keys = Object.values(hash)
        .filter((item) => item.level === level + 1 && item.parentId === id)
        .reduce(
          (a, item) => [...uncheckAllChildren(level + 1, item.id), ...a],
          []
        );
      const result = [...keys, key];
      return result;
    },
    [hash]
  );

  const getEntityType = useCallback((item) => {
    const level = item.level;
    if (level === 2) {
      return "League";
    } else if (level === 3) {
      return "Competition";
    }
  }, []);

  const handleButtonClick = useCallback(async () => {
    const data = Object.values(hash).map((item) => ({
      entityId: item.id,
      entityType: getEntityType(item),
      displayOrder: item.displayOrder || null,
    }));
    await setConfiguration(projectId, sport.id, { items: data });
    await onSubmit();
    onCloseDialog();
  }, [
    hash,
    getEntityType,
    projectId,
    sport,
    onCloseDialog,
    onSubmit,
    setConfiguration,
  ]);

  const handleHashChange = useCallback(
    (items, state) => {
      let newHash = { ...hash };
      items.forEach(({ level, id, parentId, displayOrder }) => {
        const key = `${level}-${id}`;
        if (!state) {
          const keys = uncheckAllChildren(level, id);
          keys.forEach((key) => delete newHash[key]);
        } else {
          newHash = {
            ...newHash,
            [key]: { level, id, parentId, displayOrder },
          };
        }
      });
      // const newHash = {
      //   '2-3': { displayOrder: null, id: 3, level: 2, parentId: 1 }
      // }
      setHash(newHash);
    },
    [hash, uncheckAllChildren]
  );

  useEffect(() => {
    fetchLeagues();
    setHash(initialHash);
  }, [id, projectId, fetchLeagues, initialHash]);

  return (
    <div style={{ padding: "25px" }}>
      <Button
        variant="contained"
        color={"success"}
        onClick={handleButtonClick}
        style={{ marginBottom: "25px" }}
      >
        {"Save"}
      </Button>
      {leagues &&
        leagues.map((item) => (
          <ConfigureControl
            key={item.id}
            item={item}
            hash={hash}
            level={2}
            onHashChange={handleHashChange}
            parentId={null}
            sport={sport}
          />
        ))}
    </div>
  );
}
