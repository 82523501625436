
import { execute } from "./response";
import instance from "./api";

const endpoints = {
  projects: `${process.env.REACT_APP_API_URL}/api/projects`,
};

const fetchProjects = () => {
  return execute(() => instance.get(endpoints.projects));
};

const fetchProject = (id) => {
  return execute(() => instance.get(`${endpoints.projects}/${id}`));
};

const createProject = (payload) => {
  return execute(() => instance.post(endpoints.projects, payload));
};

export { fetchProjects, fetchProject, createProject };
