import styled from "styled-components";

const AccountsEditWrapper = styled.div`
  width: 100%; 
  min-width: 500px;
  display: flex; 
  flex-direction: column;
  gap: 25px;

  h5 {
    margin: 0;
  }

  .permission-block__wrapper {
    display: flex; 
    flex-wrap: wrap;
    border: 1px solid black;
  }

  div.permission-block__item:nth-last-child(even) {
    background: #e9e9e9;
  }

  .permission-block__item {
    display: flex; 
    width: 33.3%;
    flex-direction: column;
    h4 {
      margin: 0;
      padding: 8px;
    }
  }

  .permission-block__item-title {
    text-align: center;
  }
`;


const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 25px;
  .insertButton {
    background: #2d8ac7;
    color: white;
    height: 50px;
    width: 120px;
    border-width: 0;
    cursor: pointer;

    :hover {
      background-color: #257cb6;
      border-color: transparent;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
  }
  .cancelButton {
    height: 50px;
    width: 120px;
    cursor: pointer;
    border-width: 0;

    :hover {
      background-color: #e3e3e3;
      border-color: #ccc;
      border-top-color: rgb(204, 204, 204);
      border-right-color: rgb(204, 204, 204);
      border-bottom-color: rgb(204, 204, 204);
      border-left-color: rgb(204, 204, 204);
    }
  }
`;

const AccountsTable = styled.table`
  width: 100%;
  border: 1px solid black;
  padding: 15px;
  table-layout: fixed;
  border-collapse: collapse;
  td {
    border: 1px solid black;
    padding: 4px 8px;
    text-align: start; 
  }
  thead {
    height: 50px;
    td {
      text-align: center; 
    }
  }

`;

export { AccountsEditWrapper, ButtonsWrapper, AccountsTable };