import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { getPermissions, getRoles } from "../../api/users";
import { AccountsEditWrapper } from "./style";

export default function EditAccount(props) {

  const [groupedPermissions, setGroupedPermissions] = useState({});
  const [checkedPermissions, setCheckedPermissions] = useState({});
  const [checkedProjects, setCheckedProjects] = useState({});
  const [roles, setRoles] = useState([]);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [account, setAccount] = useState({
    id: '',
    username: '',
    roleName: '',
    role: '',
    isActive: false,
    permissions: [],
    projects: [],
  });

  const projects = useSelector((state) => state.project.projects);

  const handleSubmit = useCallback(() => {
    const { role, isActive, username, id } = account;
    if (!id) {
      if (!username) {
        toast.error('You should set username!');
        return;
      } else if (!password) {
        toast.error('You should set password!');
        return;
      }
    }
    if (password !== repeatPassword) {
      toast.error('Passwords should be similar!');
      return;
    }
    const permissions = Object.values(checkedPermissions).filter((item) => item.isChecked).map((permission) => permission.id);
    const projects = Object.values(checkedProjects).filter((item) => item.isChecked).map((project) => project.id);
    const accountData = {
      password,
      role: role || 'User',
      isActive,
      permissions,
      username,
      projects,
    };

    if (id) {
      delete accountData.username;
      if (!password) {
        delete accountData.password;
      }
    }
    props.onSubmit(accountData, id);
  }, [account, checkedPermissions, checkedProjects, password, props, repeatPassword]);

  const onChange = useCallback((e) => {
    const { value, name } = e.target;
    switch (name) {
      case 'password':
        setPassword(value);
        break;
      case 'repeatPassword':
        setRepeatPassword(value);
        break;
      default:
        break;
    }
  }, []);

  const onAccountChange = useCallback((e) => {
    const target = e.target;
    const name = target.name;
    if (target.type === 'checkbox') {
      const value = target.checked;
      setAccount({ ...account, [name]: value });
    } else {
      const value = target.value;
      setAccount({ ...account, [name]: value });
    }
  }, [account]);

  const onRoleChange = useCallback((e) => {
    const value = e.target.value;
    const newCheckedPermissions = { ...checkedPermissions };
    Object.values(newCheckedPermissions).forEach((item) => {
      const attachedRole = item.roles.find((role) => role.toString() === value);
      if (attachedRole) {
        item.isChecked = true;
        item.isDisabled = true;
      } else {
        item.isChecked = false;
        item.isDisabled = false;
      }
    });
    setAccount({ ...account, role: value });
    setCheckedPermissions(newCheckedPermissions);
  }, [checkedPermissions, account]);

  const onPermissionsChange = useCallback((e) => {
    const { value, checked } = e.target;
    const newPermissions = { ...checkedPermissions };
    if (checked) {
      newPermissions[value].isChecked = true;
    } else {
      newPermissions[value].isChecked = false;
    }
    setCheckedPermissions(newPermissions);
  }, [checkedPermissions]);

  const onProjectsChange = useCallback((e) => {
    const { value, checked } = e.target;
    const newProjects = { ...checkedProjects };
    if (checked) {
      newProjects[value].isChecked = true;
    } else {
      newProjects[value].isChecked = false;
    }
    setCheckedProjects(newProjects);
  }, [checkedProjects]);

  const setup = useCallback(async () => {
    const roles = await getRoles();
    const permissions = await getPermissions();
    const groupedPermissions = permissions.reduce((a, c) => {
      if (a[c.permissionGroup]) {
        a[c.permissionGroup].push(c);
      } else {
        a[c.permissionGroup] = [c];
      }
      return a;
    }, {});

    const checkedPermissions = permissions.reduce((a, c) => {
      a[c.id] = { ...c, isChecked: false, isDisabled: false };
      return a;
    }, {});

    const checkedProjects = projects.reduce((a, c) => {
      a[c.id] = { ...c, isChecked: false, isDisabled: false };
      return a;
    }, {});

    Object.values(checkedProjects).forEach((item) => {
      const isChecked = account.projects.find((project) => project.id.toString() === item.id.toString());
      if (isChecked) {
        item.isChecked = true;
      } else {
        item.isChecked = false;
      }
    });

    Object.values(checkedPermissions).forEach((item) => {
      const attachedRole = item.roles.find((role) => role.toString() === props.account.role.toString());
      if (attachedRole) {
        item.isChecked = true;
        item.isDisabled = true;
      } else {
        item.isChecked = false;
        item.isDisabled = false;
      }
    });

    props.account.permissions.forEach((item) => {
      checkedPermissions[item].isChecked = true;
    });

    setRoles(roles);
    setAccount(props.account);
    setGroupedPermissions(groupedPermissions);
    setCheckedPermissions(checkedPermissions);
    setCheckedProjects(checkedProjects);
  }, [props.account, projects, account.projects]);

  useEffect(() => {
    setup();
  }, [setup]);

  const user = props.user;

  return (
    <AccountsEditWrapper>
      <div style={{ display: 'flex', gap: '20px' }}>
        {account && !account.id && <TextField label="Username" name="username" value={account.username} maxLength="256" onChange={onAccountChange} />}
        {account && user && roles && roles.length &&
          <TextField SelectProps={{ native: true }} label="Role" select value={account.role || "User"} disabled={user.id === account.id} onChange={onRoleChange}>
            {roles && roles.map((item, index) =>
              <option value={item.id} key={index}>{item.name}</option>
            )}
          </TextField>}
      </div>
      {user && account && Object.keys(groupedPermissions).length && <div>
        <h2>Permissions</h2>
        <div className="permission-block__wrapper">
          {Object.keys(groupedPermissions).map((item, index) => <div key={index} className="permission-block__item">
            <h4 className="permission-block__item-title">{item}</h4>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', padding: '8px' }}>
              {groupedPermissions[item].map((permission, index) =>
                <FormControlLabel key={index} control={<Checkbox
                  name="checkedPermissions"
                  type="checkbox"
                  value={permission.id}
                  checked={checkedPermissions[permission.id].isChecked}
                  disabled={checkedPermissions[permission.id].isDisabled || user.id === account.id}
                  onChange={onPermissionsChange}
                />} label={permission.name} />
              )}
            </div>
          </div>)}
        </div>
      </div>}
      <div style={{ display: 'flex', gap: '12px', width: '100%' }}>
        <TextField
          label="Password"
          name="password"
          type={"password"}
          value={password}
          maxLength={"256"}
          onChange={onChange}
        />

        <TextField
          label="Repeat Password"
          name="repeatPassword"
          type={"password"}
          value={repeatPassword}
          maxLength={"256"}
          onChange={onChange}
        />

      </div>
      {user && account && <FormControlLabel control={<Checkbox
        name="isActive"
        type="checkbox"
        value={account.isActive}
        checked={account.isActive}
        onChange={onAccountChange}
        disabled={user.id === account.id}
      />} label="Is Active" />}
      {account.role !== 'Admin' && account.role !== 'SuperAdmin' && <div>
        <h2>Projects</h2>
        <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
          {Object.values(checkedProjects).map((project, index) =>
            <FormControlLabel key={index} control={<Checkbox
              name="projects"
              type="checkbox"
              value={project.id}
              checked={checkedProjects[project.id].isChecked}
              disabled={checkedProjects[project.id].isDisabled}
              onChange={onProjectsChange}
            />} label={project.name} />
          )}
        </div>
      </div>}


      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
        >
          {account.id ? 'Save' : 'Create'}
        </Button>
        <Button variant="contained" color="error" onClick={props.handleClose}>
          Cancel
        </Button>
      </div>
    </AccountsEditWrapper>
  );
}
