import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState } from "react";
import EditContent from "./editContent";
import { useCallback } from "react";

export default function EditContentTabs({
  item,
  keys,
  closeDialog,
  getContent,
  setContent,
}) {
  const [value, setValue] = useState("0");

  const handleFetchContent = useCallback(
    async (contentKey) => {
      const data = await getContent(contentKey);
      return data;
    },
    [getContent]
  );

  const handleSetContent = useCallback(
    async (contentKey, content) => {
      const data = await setContent(contentKey, content);
      return data;
    },
    [setContent]
  );

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  //TODO: update function
  const createContentKey = useCallback((item, contentPostfix) => {
    if (!item.contentKey && contentPostfix) {
      return contentPostfix;
    }
    const contentKey = contentPostfix
      ? `${item.contentKey}-${contentPostfix}`
      : `${item.contentKey}`;
    return contentKey;
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
        typography: "body1",
      }}
    >
      {item && keys.length && (
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              centered
            >
              {keys.map((key, index) => (
                <Tab key={index} label={key.title} value={index.toString()} />
              ))}
            </TabList>
          </Box>
          {keys.map((key, index) => (
            <TabPanel
              key={index}
              value={index.toString()}
            >
              <EditContent
                onSave={closeDialog}
                getContent={handleFetchContent}
                setContent={handleSetContent}
                showFields={false}
                contentKey={createContentKey(item, key.contentPostfix)}
              />
            </TabPanel>
          ))}
        </TabContext>
      )}
    </Box>
  );
}
