import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import MatchPredictions from "../../components/matchPredictions";
import { MatchPredictionsPageWrapper } from "./style";

export default function MatchPredictionPage() {
  return (
    <MatchPredictionsPageWrapper>
      <Link href="/prediction-tables">
        <Button variant="contained">Prediction Tables</Button>
      </Link>
      <MatchPredictions />
    </MatchPredictionsPageWrapper>
  );
}
