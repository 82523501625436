import instance from "./api";

const endpoints = {
  competitors: `${process.env.REACT_APP_API_URL}/api/competitors`,
  competitor: (id) => `${process.env.REACT_APP_API_URL}/api/competitors/${id}`,
};

const fetchAllCompetitors = async (params) => {
  const queryParams = params
    ? params.map((x) => `${x.key}=${x.value}`).join("&")
    : "";
  const res = await instance.get(
    `${endpoints.competitors}${queryParams ? `?${queryParams}` : ""}`
  );
  return res.data;
};

const addCompetitorShortName = (id, name) => {
  return instance.put(endpoints.competitor(id), name);
};

export { fetchAllCompetitors, addCompetitorShortName };
