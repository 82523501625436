import { applyQueryParams } from "../utils/request";
import instance from "./api";

const getCountries = async (params) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/countries`;
  const res = await instance.get(applyQueryParams(endpoint, params));
  return res.data;
};

export { getCountries };
