import { applyQueryParams } from "../utils/request";
import instance from "./api";

const endpoints = {
  content: (projectId, type, contentKey) =>
    `${process.env.REACT_APP_API_URL}/api/projects/${projectId}/content/${type}/${contentKey}`,
};

const getContent = async (projectId, type, contentKey, params) => {
  const queryParams = applyQueryParams(params);
  const endpoint = endpoints.content(projectId, type, contentKey);
  const res = await instance.get(
    `${endpoint}${queryParams ? `?${queryParams}` : ""}`
  );
  return {content: res.data};
};

const setContent = async (projectId, type, contentKey, data) => {
  const res = await instance.put(endpoints.content(projectId, type, contentKey), data);
  return {content: res.data};
};

export { getContent, setContent };
