import { useEffect, useState, useCallback } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Breadcrumb from "./breadcrumb";

export default function BreadcrumbsList({ breadcrumbs, onContentChange }) {
  const [localBreadcrumbs, setLocalBreadcrumbs] = useState([]);
  const [newBreadcrumb, setNewBreadcrumb] = useState({ text: "", link: "" });

  const handleLocalBreadcrumbsChange = useCallback(
    (breadcrumb, index) => {
      const newLocalBreadcrumbs = [...localBreadcrumbs];
      newLocalBreadcrumbs[index] = breadcrumb;
      setLocalBreadcrumbs(newLocalBreadcrumbs);
      onContentChange("breadCrumbs", newLocalBreadcrumbs);
    },
    [localBreadcrumbs, onContentChange]
  );

  const handleDelete = useCallback(
    (index) => {
      const newLocalBreadcrumbs = [...localBreadcrumbs];
      newLocalBreadcrumbs.splice(index, 1);
      setLocalBreadcrumbs(newLocalBreadcrumbs);
      onContentChange("breadCrumbs", newLocalBreadcrumbs);
    },
    [localBreadcrumbs, onContentChange]
  );

  const handleAddBreadcrumb = useCallback(() => {
    if (!newBreadcrumb.text || !newBreadcrumb.link) return;
    const newLocalBreadcrumbs = [...localBreadcrumbs, newBreadcrumb];
    setLocalBreadcrumbs(newLocalBreadcrumbs);
    onContentChange("breadCrumbs", newLocalBreadcrumbs);
    setNewBreadcrumb({ text: "", link: "" });
  }, [newBreadcrumb, localBreadcrumbs, onContentChange]);

  const handleChange = useCallback(
    (event) => {
      const { name, value } = event.target;
      setNewBreadcrumb({ ...newBreadcrumb, [name]: value });
    },
    [newBreadcrumb]
  );

  useEffect(() => {
    setLocalBreadcrumbs([...breadcrumbs]);
  }, [breadcrumbs]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "35px",
        width: "100%",
      }}
    >
      <h1 style={{ textAlign: "center", margin: "0" }}>Breadcrumbs</h1>
      {localBreadcrumbs.length > 0 &&
        localBreadcrumbs.map((breadcrumb, index) => (
          <Breadcrumb
            key={index}
            breadcrumb={breadcrumb}
            index={index}
            handleDelete={handleDelete}
            handleLocalBreadcrumbsChange={handleLocalBreadcrumbsChange}
          />
        ))}
      {localBreadcrumbs.length === 0 && <span>Breadcrumbs list is empty</span>}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <TextField
          name="text"
          variant="outlined"
          size="small"
          label="Text"
          fullWidth
          value={newBreadcrumb.text}
          onChange={handleChange}
        />
        <TextField
          name="link"
          variant="outlined"
          size="small"
          label="Link"
          fullWidth
          multiline
          minRows={3}
          value={newBreadcrumb.link}
          onChange={handleChange}
        />
        <Button
          variant="contained"
          color={"primary"}
          onClick={handleAddBreadcrumb}
        >
          Add
        </Button>
      </div>
    </div>
  );
}
