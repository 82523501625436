import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useCallback, useState } from "react";


import { useSelector } from "react-redux";
import PointsTableItem from "./pointsTableItem";

export default function MatchCenter() {
  const [value, setValue] = useState("0");

  const sportKeys = useSelector((state) => state.sports.sportKeys);

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          centered
        >
          {sportKeys.map((item, index) => (
            <Tab key={index} label={item.title} value={index.toString()} />
          ))}
        </TabList>
      </Box>
      {sportKeys.map((key, index) => (
        <TabPanel
          key={index}
          value={index.toString()}
        >
          <PointsTableItem sport={key} />
        </TabPanel>
      ))}
    </TabContext>
  );
}
