import TextField from "@mui/material/TextField";
import { useCallback, useMemo } from "react";

const labels = {
  'description': 'HTML Text',
  'meta': 'Meta Description',
  'topDescription': 'Top HTML Text',
  'bottomDescription': 'Bottom HTML Text',
};

export default function Section({ section, handleLocalSectionsChange }) {
  const handleChange = useCallback(
    (event) => {
      handleLocalSectionsChange(section.key, event.target.value);
    },
    [handleLocalSectionsChange, section.key]
  );

  const label = useMemo(() => labels[section.key] || section.key, [section?.key]);

  return (
    <div>
      <TextField
        variant="outlined"
        size="small"
        label={label}
        multiline
        fullWidth
        minRows={2}
        value={section.value}
        onChange={handleChange}
      />
    </div>
  );
}
